import { RouteUtils } from "~~/utils/routes.utils";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path === "/") {
    const current_meeting = localStorage.getItem("current_meeting");
    if (current_meeting) {
      await useRouter().push({
        path: RouteUtils.getMeetingHome(current_meeting),
        force: true,
      });
    }
  }
});
