import { defineStore } from "pinia";

/*
This store manages User and Account state including the ActiveAccount
It is used in the Account administration page and the header due to it's account switching features.
*/

export interface DetailedNotification {
  title: string;
  detail: string;
  type: NotificationType;
  notifyTime: number;
  icon: string;
  contentType: "detailed";
}

export interface Notification {
  message: string;
  type: NotificationType;
  notifyTime: number;
  icon: string;
  contentType: "basic";
}
export interface ErrorNotification {
  message: string;
  type: NotificationType;
  notifyTime: number;
  icon: string;
  contentType: "error";
}

export enum NotificationType {
  Primary = "primary",
  Success = "success",
  Neutral = "neutral",
  Warning = "warning",
  Danger = "danger",
}

const defaultIconByType: Record<NotificationType, string> = {
  [NotificationType.Primary]: "circle-info",
  [NotificationType.Success]: "circle-check",
  [NotificationType.Neutral]: "circle-info",
  [NotificationType.Warning]: "triangle-exclamation",
  [NotificationType.Danger]: "circle-exclamation",
};

interface State {
  notifications: Array<Notification | ErrorNotification | DetailedNotification>;
}

export const useNotifyStore = defineStore("notify", {
  state: (): State => {
    return {
      notifications: [],
    };
  },
  actions: {
    notifyError(
      messageOrError: unknown,
      type: NotificationType = NotificationType.Primary,
      icon: string = defaultIconByType[type],
      duration: number = 5000
    ) {
      let message: string = "";
      if (messageOrError instanceof Error)
        message = (messageOrError as Error).message;
      if (typeof messageOrError === "string") message = messageOrError;

      const notification: ErrorNotification = {
        message,
        type,
        icon,
        notifyTime: Date.now(),
        contentType: "error",
      };
      this.notifications = [...this.notifications, notification];
      setTimeout(this.removeNotification.bind(this), duration, notification);
    },

    notifyDetailed(
      title: string,
      detail: string,
      type: NotificationType = NotificationType.Primary,
      icon: string = defaultIconByType[type],
      duration: number = 5000
    ) {
      const notification: DetailedNotification = {
        title,
        detail,
        type,
        icon,
        notifyTime: Date.now(),
        contentType: "detailed",
      };
      console.log("notifyDetailed", notification);

      this.notifications = [...this.notifications, notification];
      setTimeout(this.removeNotification.bind(this), duration, notification);
    },

    notify(
      message: string,
      type: NotificationType = NotificationType.Primary,
      icon: string = defaultIconByType[type],
      duration: number = 5000
    ) {
      const notification: Notification = {
        message,
        type,
        icon,
        notifyTime: Date.now(),
        contentType: "basic",
      };
      this.notifications = [...this.notifications, notification];
      setTimeout(this.removeNotification.bind(this), duration, notification);
    },

    removeNotification(notification: Notification) {
      this.notifications = this.notifications.filter(
        (n) => n.notifyTime != notification.notifyTime
      );
    },
  },
});
