/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyRoutineApply
 */
export interface DailyRoutineApply {
    /**
     * 
     * @type {Array<Date>}
     * @memberof DailyRoutineApply
     */
    days: Array<Date>;
    /**
     * 
     * @type {boolean}
     * @memberof DailyRoutineApply
     */
    erase?: boolean;
}

/**
 * Check if a given object implements the DailyRoutineApply interface.
 */
export function instanceOfDailyRoutineApply(value: object): boolean {
    if (!('days' in value)) return false;
    return true;
}

export function DailyRoutineApplyFromJSON(json: any): DailyRoutineApply {
    return DailyRoutineApplyFromJSONTyped(json, false);
}

export function DailyRoutineApplyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyRoutineApply {
    if (json == null) {
        return json;
    }
    return {
        
        'days': json['days'],
        'erase': json['erase'] == null ? undefined : json['erase'],
    };
}

export function DailyRoutineApplyToJSON(value?: DailyRoutineApply | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'days': value['days'],
        'erase': value['erase'],
    };
}

