/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
} from './NotNestedMeetingRole';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedDocTemplate
 */
export interface PatchedDocTemplate {
    /**
     * 
     * @type {string}
     * @memberof PatchedDocTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDocTemplate
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDocTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDocTemplate
     */
    parent_template?: string;
    /**
     * 
     * @type {Array<NotNestedMeetingRole>}
     * @memberof PatchedDocTemplate
     */
    readonly involved_roles?: Array<NotNestedMeetingRole>;
    /**
     * 
     * @type {string}
     * @memberof PatchedDocTemplate
     */
    category?: string;
}

/**
 * Check if a given object implements the PatchedDocTemplate interface.
 */
export function instanceOfPatchedDocTemplate(value: object): boolean {
    return true;
}

export function PatchedDocTemplateFromJSON(json: any): PatchedDocTemplate {
    return PatchedDocTemplateFromJSONTyped(json, false);
}

export function PatchedDocTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDocTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'parent_template': json['parent_template'] == null ? undefined : json['parent_template'],
        'involved_roles': json['involved_roles'] == null ? undefined : ((json['involved_roles'] as Array<any>).map(NotNestedMeetingRoleFromJSON)),
        'category': json['category'] == null ? undefined : json['category'],
    };
}

export function PatchedDocTemplateToJSON(value?: Omit<PatchedDocTemplate, 'involved_roles'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'label': value['label'],
        'description': value['description'],
        'parent_template': value['parent_template'],
        'category': value['category'],
    };
}

