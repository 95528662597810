/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventTypeEnum } from './EventTypeEnum';
import {
    EventTypeEnumFromJSON,
    EventTypeEnumFromJSONTyped,
    EventTypeEnumToJSON,
} from './EventTypeEnum';
import type { VisibilityEnum } from './VisibilityEnum';
import {
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedEvent
 */
export interface NotNestedEvent {
    /**
     * 
     * @type {string}
     * @memberof NotNestedEvent
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedEvent
     */
    activity: string;
    /**
     * 
     * @type {Date}
     * @memberof NotNestedEvent
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotNestedEvent
     */
    end_date: Date;
    /**
     * Must not be null, a Track must be linked to a meeting
     * @type {string}
     * @memberof NotNestedEvent
     */
    readonly meeting_id: string;
    /**
     * Room where is located the event ; can be empty if no room has been defined yet during the meeting
     * @type {string}
     * @memberof NotNestedEvent
     */
    room?: string;
    /**
     * Participant team in charge of this event, can be empty if no team in charge
     * @type {string}
     * @memberof NotNestedEvent
     */
    team_in_charge?: string;
    /**
     * 
     * @type {EventTypeEnum}
     * @memberof NotNestedEvent
     */
    event_type?: EventTypeEnum;
    /**
     * Commentaire interne en équipe de formation
     * @type {string}
     * @memberof NotNestedEvent
     */
    comment?: string;
    /**
     * Supervisors in charge to prepare the Event, can be empty if no supervisor defined (all supervisors or no-one)
     * @type {Array<string>}
     * @memberof NotNestedEvent
     */
    supervisors?: Array<string>;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof NotNestedEvent
     */
    visibility?: VisibilityEnum;
}

/**
 * Check if a given object implements the NotNestedEvent interface.
 */
export function instanceOfNotNestedEvent(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('activity' in value)) return false;
    if (!('start_date' in value)) return false;
    if (!('end_date' in value)) return false;
    if (!('meeting_id' in value)) return false;
    return true;
}

export function NotNestedEventFromJSON(json: any): NotNestedEvent {
    return NotNestedEventFromJSONTyped(json, false);
}

export function NotNestedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'activity': json['activity'],
        'start_date': (new Date(json['start_date'])),
        'end_date': (new Date(json['end_date'])),
        'meeting_id': json['meeting_id'],
        'room': json['room'] == null ? undefined : json['room'],
        'team_in_charge': json['team_in_charge'] == null ? undefined : json['team_in_charge'],
        'event_type': json['event_type'] == null ? undefined : EventTypeEnumFromJSON(json['event_type']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'supervisors': json['supervisors'] == null ? undefined : json['supervisors'],
        'visibility': json['visibility'] == null ? undefined : VisibilityEnumFromJSON(json['visibility']),
    };
}

export function NotNestedEventToJSON(value?: Omit<NotNestedEvent, 'id'|'meeting_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activity': value['activity'],
        'start_date': ((value['start_date']).toISOString()),
        'end_date': ((value['end_date']).toISOString()),
        'room': value['room'],
        'team_in_charge': value['team_in_charge'],
        'event_type': EventTypeEnumToJSON(value['event_type']),
        'comment': value['comment'],
        'supervisors': value['supervisors'],
        'visibility': VisibilityEnumToJSON(value['visibility']),
    };
}

