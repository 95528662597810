/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface TeamDailyTask
 */
export interface TeamDailyTask {
    /**
     * 
     * @type {string}
     * @memberof TeamDailyTask
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDailyTask
     */
    daily_task: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamDailyTask
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof TeamDailyTask
     */
    team_in_charge: string;
}

/**
 * Check if a given object implements the TeamDailyTask interface.
 */
export function instanceOfTeamDailyTask(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('daily_task' in value)) return false;
    if (!('date' in value)) return false;
    if (!('team_in_charge' in value)) return false;
    return true;
}

export function TeamDailyTaskFromJSON(json: any): TeamDailyTask {
    return TeamDailyTaskFromJSONTyped(json, false);
}

export function TeamDailyTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDailyTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'daily_task': json['daily_task'],
        'date': (new Date(json['date'])),
        'team_in_charge': json['team_in_charge'],
    };
}

export function TeamDailyTaskToJSON(value?: Omit<TeamDailyTask, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daily_task': value['daily_task'],
        'date': ((value['date']).toISOString().substring(0,10)),
        'team_in_charge': value['team_in_charge'],
    };
}

