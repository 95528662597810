/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPublicActivityCategory
 */
export interface NotNestedPublicActivityCategory {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivityCategory
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivityCategory
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivityCategory
     */
    text_color?: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivityCategory
     */
    label: string;
    /**
     * When a meeting is set, the category is custom to a particular meeting
     * @type {string}
     * @memberof NotNestedPublicActivityCategory
     */
    readonly meeting: string | null;
}

/**
 * Check if a given object implements the NotNestedPublicActivityCategory interface.
 */
export function instanceOfNotNestedPublicActivityCategory(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('meeting' in value)) return false;
    return true;
}

export function NotNestedPublicActivityCategoryFromJSON(json: any): NotNestedPublicActivityCategory {
    return NotNestedPublicActivityCategoryFromJSONTyped(json, false);
}

export function NotNestedPublicActivityCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPublicActivityCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'color': json['color'] == null ? undefined : json['color'],
        'text_color': json['text_color'] == null ? undefined : json['text_color'],
        'label': json['label'],
        'meeting': json['meeting'],
    };
}

export function NotNestedPublicActivityCategoryToJSON(value?: Omit<NotNestedPublicActivityCategory, 'id'|'meeting'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'color': value['color'],
        'text_color': value['text_color'],
        'label': value['label'],
    };
}

