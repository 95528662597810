import { authenticationService } from "@/core/services";

export let localhost: string | null = null;
export let apiUrl: string | null = null;

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  localhost = config.public.baseUrl;
  apiUrl = config.public.apiBaseUrl;
  authenticationService.createInstance();
});
