/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocTemplate,
  Meeting,
  MeetingGestionnaire,
  MeetingParticipant,
  OwnMeetingParticipant,
  OwnMeetingUpdateParticipant,
  PaginatedDocTemplateList,
  PaginatedMeetingList,
  PaginatedMeetingParticipantList,
  PatchedDocTemplate,
  PatchedMeeting,
  PatchedMeetingGestionnaire,
  PatchedMeetingParticipant,
  ReadonlyMeeting,
} from '../models/index';
import {
    DocTemplateFromJSON,
    DocTemplateToJSON,
    MeetingFromJSON,
    MeetingToJSON,
    MeetingGestionnaireFromJSON,
    MeetingGestionnaireToJSON,
    MeetingParticipantFromJSON,
    MeetingParticipantToJSON,
    OwnMeetingParticipantFromJSON,
    OwnMeetingParticipantToJSON,
    OwnMeetingUpdateParticipantFromJSON,
    OwnMeetingUpdateParticipantToJSON,
    PaginatedDocTemplateListFromJSON,
    PaginatedDocTemplateListToJSON,
    PaginatedMeetingListFromJSON,
    PaginatedMeetingListToJSON,
    PaginatedMeetingParticipantListFromJSON,
    PaginatedMeetingParticipantListToJSON,
    PatchedDocTemplateFromJSON,
    PatchedDocTemplateToJSON,
    PatchedMeetingFromJSON,
    PatchedMeetingToJSON,
    PatchedMeetingGestionnaireFromJSON,
    PatchedMeetingGestionnaireToJSON,
    PatchedMeetingParticipantFromJSON,
    PatchedMeetingParticipantToJSON,
    ReadonlyMeetingFromJSON,
    ReadonlyMeetingToJSON,
} from '../models/index';

export interface ExportsCreateRequest {
    docTemplate: Omit<DocTemplate, 'involved_roles'>;
}

export interface ExportsDestroyRequest {
    uuid: string;
}

export interface ExportsListRequest {
    limit?: number;
    offset?: number;
    ordering?: string;
    parentTemplate?: string;
    search?: string;
}

export interface ExportsPartialUpdateRequest {
    uuid: string;
    patchedDocTemplate?: Omit<PatchedDocTemplate, 'involved_roles'>;
}

export interface ExportsRetrieveRequest {
    uuid: string;
}

export interface ExportsUpdateRequest {
    uuid: string;
    docTemplate: Omit<DocTemplate, 'involved_roles'>;
}

export interface GestionnaireMeetingsListRequest {
    centreRessource?: string;
    endDate?: Date;
    limit?: number;
    meetingTypeIdIn?: Array<string>;
    offset?: number;
    ordering?: string;
    search?: string;
    startDate?: Date;
}

export interface GestionnaireMeetingsMyParticipationPartialUpdateRequest {
    uuid: string;
    patchedMeeting?: Omit<PatchedMeeting, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'>;
}

export interface GestionnaireMeetingsMyParticipationRetrieveRequest {
    uuid: string;
}

export interface GestionnaireMeetingsMyParticipationUpdateRequest {
    uuid: string;
    meeting: Omit<Meeting, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'>;
}

export interface GestionnaireMeetingsMyScopeListListRequest {
    centreRessource?: string;
    endDate?: Date;
    limit?: number;
    meetingTypeIdIn?: Array<string>;
    offset?: number;
    ordering?: string;
    search?: string;
    startDate?: Date;
}

export interface GestionnaireMeetingsPartialUpdateRequest {
    uuid: string;
    patchedMeetingGestionnaire?: Omit<PatchedMeetingGestionnaire, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'>;
}

export interface GestionnaireMeetingsResetParticipantsAvatarDestroyRequest {
    uuid: string;
}

export interface GestionnaireMeetingsResetParticipantsSignatureDestroyRequest {
    uuid: string;
}

export interface GestionnaireMeetingsRetrieveRequest {
    uuid: string;
}

export interface GestionnaireMeetingsSyncAvisTagadaCreateRequest {
    uuid: string;
}

export interface GestionnaireMeetingsSyncFromIntranetCreateRequest {
    uuid: string;
}

export interface GestionnaireMeetingsSyncParticipantsTagadaCreateRequest {
    uuid: string;
}

export interface GestionnaireMeetingsUpdateRequest {
    uuid: string;
    meetingGestionnaire: Omit<MeetingGestionnaire, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'>;
}

export interface GestionnaireParticipantsListRequest {
    uuid: string;
    limit?: number;
    meetingEndDate?: Date;
    meetingLabel?: string;
    meetingStartDate?: Date;
    meetingStructureOrganisatrice?: string;
    offset?: number;
    ordering?: string;
    search?: string;
    userFirstName?: string;
    userLastName?: string;
    userUsername?: string;
}

export interface GestionnaireParticipantsPartialUpdateRequest {
    id: string;
    uuid: string;
    patchedMeetingParticipant?: Omit<PatchedMeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'>;
}

export interface GestionnaireParticipantsRetrieveRequest {
    id: string;
    uuid: string;
}

export interface GestionnaireParticipantsUpdateRequest {
    id: string;
    uuid: string;
    meetingParticipant: Omit<MeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'>;
}

/**
 * 
 */
export class GestionnaireApi extends runtime.BaseAPI {

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsCreateRaw(requestParameters: ExportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['docTemplate'] == null) {
            throw new runtime.RequiredError(
                'docTemplate',
                'Required parameter "docTemplate" was null or undefined when calling exportsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocTemplateToJSON(requestParameters['docTemplate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsCreate(requestParameters: ExportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.exportsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsDestroyRaw(requestParameters: ExportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsDestroy(requestParameters: ExportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exportsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsListRaw(requestParameters: ExportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDocTemplateList>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parentTemplate'] != null) {
            queryParameters['parent_template'] = requestParameters['parentTemplate'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDocTemplateListFromJSON(jsonValue));
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsList(requestParameters: ExportsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDocTemplateList> {
        const response = await this.exportsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsPartialUpdateRaw(requestParameters: ExportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDocTemplateToJSON(requestParameters['patchedDocTemplate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsPartialUpdate(requestParameters: ExportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.exportsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsRetrieveRaw(requestParameters: ExportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsRetrieve(requestParameters: ExportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.exportsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsUpdateRaw(requestParameters: ExportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportsUpdate().'
            );
        }

        if (requestParameters['docTemplate'] == null) {
            throw new runtime.RequiredError(
                'docTemplate',
                'Required parameter "docTemplate" was null or undefined when calling exportsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocTemplateToJSON(requestParameters['docTemplate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * This viewset provides actions to manage DocTemplates
     */
    async exportsUpdate(requestParameters: ExportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.exportsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste tous les stages quand je suis gestionnaire
     */
    async gestionnaireMeetingsListRaw(requestParameters: GestionnaireMeetingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingTypeIdIn'] != null) {
            queryParameters['meeting_type__id__in'] = requestParameters['meetingTypeIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingListFromJSON(jsonValue));
    }

    /**
     * Liste tous les stages quand je suis gestionnaire
     */
    async gestionnaireMeetingsList(requestParameters: GestionnaireMeetingsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingList> {
        const response = await this.gestionnaireMeetingsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationPartialUpdateRaw(requestParameters: GestionnaireMeetingsMyParticipationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingUpdateParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsMyParticipationPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingToJSON(requestParameters['patchedMeeting']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingUpdateParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationPartialUpdate(requestParameters: GestionnaireMeetingsMyParticipationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingUpdateParticipant> {
        const response = await this.gestionnaireMeetingsMyParticipationPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationRetrieveRaw(requestParameters: GestionnaireMeetingsMyParticipationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsMyParticipationRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationRetrieve(requestParameters: GestionnaireMeetingsMyParticipationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingParticipant> {
        const response = await this.gestionnaireMeetingsMyParticipationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationUpdateRaw(requestParameters: GestionnaireMeetingsMyParticipationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingUpdateParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsMyParticipationUpdate().'
            );
        }

        if (requestParameters['meeting'] == null) {
            throw new runtime.RequiredError(
                'meeting',
                'Required parameter "meeting" was null or undefined when calling gestionnaireMeetingsMyParticipationUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingToJSON(requestParameters['meeting']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingUpdateParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async gestionnaireMeetingsMyParticipationUpdate(requestParameters: GestionnaireMeetingsMyParticipationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingUpdateParticipant> {
        const response = await this.gestionnaireMeetingsMyParticipationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste les stages dont mes structures sont organisatrices
     */
    async gestionnaireMeetingsMyScopeListListRaw(requestParameters: GestionnaireMeetingsMyScopeListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingTypeIdIn'] != null) {
            queryParameters['meeting_type__id__in'] = requestParameters['meetingTypeIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/my-scope-list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingListFromJSON(jsonValue));
    }

    /**
     * Liste les stages dont mes structures sont organisatrices
     */
    async gestionnaireMeetingsMyScopeListList(requestParameters: GestionnaireMeetingsMyScopeListListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingList> {
        const response = await this.gestionnaireMeetingsMyScopeListListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsPartialUpdateRaw(requestParameters: GestionnaireMeetingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingGestionnaire>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingGestionnaireToJSON(requestParameters['patchedMeetingGestionnaire']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingGestionnaireFromJSON(jsonValue));
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsPartialUpdate(requestParameters: GestionnaireMeetingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingGestionnaire> {
        const response = await this.gestionnaireMeetingsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsResetParticipantsAvatarDestroyRaw(requestParameters: GestionnaireMeetingsResetParticipantsAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsResetParticipantsAvatarDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/reset-participants-avatar/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsResetParticipantsAvatarDestroy(requestParameters: GestionnaireMeetingsResetParticipantsAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gestionnaireMeetingsResetParticipantsAvatarDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsResetParticipantsSignatureDestroyRaw(requestParameters: GestionnaireMeetingsResetParticipantsSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsResetParticipantsSignatureDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/reset-participants-signature/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsResetParticipantsSignatureDestroy(requestParameters: GestionnaireMeetingsResetParticipantsSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gestionnaireMeetingsResetParticipantsSignatureDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Lit le stage
     */
    async gestionnaireMeetingsRetrieveRaw(requestParameters: GestionnaireMeetingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingGestionnaire>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingGestionnaireFromJSON(jsonValue));
    }

    /**
     * Lit le stage
     */
    async gestionnaireMeetingsRetrieve(requestParameters: GestionnaireMeetingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingGestionnaire> {
        const response = await this.gestionnaireMeetingsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise les avis de stages des participants de la formation avec Tagada : l\'intranet ddcs
     */
    async gestionnaireMeetingsSyncAvisTagadaCreateRaw(requestParameters: GestionnaireMeetingsSyncAvisTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Meeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsSyncAvisTagadaCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/sync-avis-tagada/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFromJSON(jsonValue));
    }

    /**
     * Synchronise les avis de stages des participants de la formation avec Tagada : l\'intranet ddcs
     */
    async gestionnaireMeetingsSyncAvisTagadaCreate(requestParameters: GestionnaireMeetingsSyncAvisTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Meeting> {
        const response = await this.gestionnaireMeetingsSyncAvisTagadaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise une formation. Synchronise aussi les participants à la formation
     */
    async gestionnaireMeetingsSyncFromIntranetCreateRaw(requestParameters: GestionnaireMeetingsSyncFromIntranetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadonlyMeeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsSyncFromIntranetCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/sync-from-intranet/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadonlyMeetingFromJSON(jsonValue));
    }

    /**
     * Synchronise une formation. Synchronise aussi les participants à la formation
     */
    async gestionnaireMeetingsSyncFromIntranetCreate(requestParameters: GestionnaireMeetingsSyncFromIntranetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadonlyMeeting> {
        const response = await this.gestionnaireMeetingsSyncFromIntranetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise  les participants de la formation avec Tagada : l\'intranet ddcs
     */
    async gestionnaireMeetingsSyncParticipantsTagadaCreateRaw(requestParameters: GestionnaireMeetingsSyncParticipantsTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Meeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsSyncParticipantsTagadaCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/sync-participants-tagada/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFromJSON(jsonValue));
    }

    /**
     * Synchronise  les participants de la formation avec Tagada : l\'intranet ddcs
     */
    async gestionnaireMeetingsSyncParticipantsTagadaCreate(requestParameters: GestionnaireMeetingsSyncParticipantsTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Meeting> {
        const response = await this.gestionnaireMeetingsSyncParticipantsTagadaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsUpdateRaw(requestParameters: GestionnaireMeetingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingGestionnaire>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireMeetingsUpdate().'
            );
        }

        if (requestParameters['meetingGestionnaire'] == null) {
            throw new runtime.RequiredError(
                'meetingGestionnaire',
                'Required parameter "meetingGestionnaire" was null or undefined when calling gestionnaireMeetingsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-meetings/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingGestionnaireToJSON(requestParameters['meetingGestionnaire']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingGestionnaireFromJSON(jsonValue));
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async gestionnaireMeetingsUpdate(requestParameters: GestionnaireMeetingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingGestionnaire> {
        const response = await this.gestionnaireMeetingsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste tous les participants aux actions de formation où je suis gestionnaire
     */
    async gestionnaireParticipantsListRaw(requestParameters: GestionnaireParticipantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingParticipantList>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireParticipantsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingEndDate'] != null) {
            queryParameters['meeting__end_date'] = (requestParameters['meetingEndDate'] as any).toISOString();
        }

        if (requestParameters['meetingLabel'] != null) {
            queryParameters['meeting__label'] = requestParameters['meetingLabel'];
        }

        if (requestParameters['meetingStartDate'] != null) {
            queryParameters['meeting__start_date'] = (requestParameters['meetingStartDate'] as any).toISOString();
        }

        if (requestParameters['meetingStructureOrganisatrice'] != null) {
            queryParameters['meeting__structure_organisatrice'] = requestParameters['meetingStructureOrganisatrice'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['userFirstName'] != null) {
            queryParameters['user__first_name'] = requestParameters['userFirstName'];
        }

        if (requestParameters['userLastName'] != null) {
            queryParameters['user__last_name'] = requestParameters['userLastName'];
        }

        if (requestParameters['userUsername'] != null) {
            queryParameters['user__username'] = requestParameters['userUsername'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-participants/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingParticipantListFromJSON(jsonValue));
    }

    /**
     * Liste tous les participants aux actions de formation où je suis gestionnaire
     */
    async gestionnaireParticipantsList(requestParameters: GestionnaireParticipantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingParticipantList> {
        const response = await this.gestionnaireParticipantsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gestionnaireParticipantsPartialUpdateRaw(requestParameters: GestionnaireParticipantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling gestionnaireParticipantsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireParticipantsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-participants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingParticipantToJSON(requestParameters['patchedMeetingParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     */
    async gestionnaireParticipantsPartialUpdate(requestParameters: GestionnaireParticipantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.gestionnaireParticipantsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère un participant par uuid
     */
    async gestionnaireParticipantsRetrieveRaw(requestParameters: GestionnaireParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling gestionnaireParticipantsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireParticipantsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-participants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Récupère un participant par uuid
     */
    async gestionnaireParticipantsRetrieve(requestParameters: GestionnaireParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.gestionnaireParticipantsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gestionnaireParticipantsUpdateRaw(requestParameters: GestionnaireParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling gestionnaireParticipantsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling gestionnaireParticipantsUpdate().'
            );
        }

        if (requestParameters['meetingParticipant'] == null) {
            throw new runtime.RequiredError(
                'meetingParticipant',
                'Required parameter "meetingParticipant" was null or undefined when calling gestionnaireParticipantsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/gestionnaire-participants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingParticipantToJSON(requestParameters['meetingParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     */
    async gestionnaireParticipantsUpdate(requestParameters: GestionnaireParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.gestionnaireParticipantsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
