/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ALL` - all
 * * `TEAM` - team
 * * `SUPERVISORS` - supervisors
 * @export
 */
export const VisibilityEnum = {
    All: 'ALL',
    Team: 'TEAM',
    Supervisors: 'SUPERVISORS'
} as const;
export type VisibilityEnum = typeof VisibilityEnum[keyof typeof VisibilityEnum];


export function instanceOfVisibilityEnum(value: any): boolean {
    return Object.values(VisibilityEnum).includes(value);
}

export function VisibilityEnumFromJSON(json: any): VisibilityEnum {
    return VisibilityEnumFromJSONTyped(json, false);
}

export function VisibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilityEnum {
    return json as VisibilityEnum;
}

export function VisibilityEnumToJSON(value?: VisibilityEnum | null): any {
    return value as any;
}

