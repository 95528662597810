/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `JOURNEE_TYPE` - abstract
 * * `CONCRET` - concret
 * @export
 */
export const EventTypeEnum = {
    JourneeType: 'JOURNEE_TYPE',
    Concret: 'CONCRET'
} as const;
export type EventTypeEnum = typeof EventTypeEnum[keyof typeof EventTypeEnum];


export function instanceOfEventTypeEnum(value: any): boolean {
    return Object.values(EventTypeEnum).includes(value);
}

export function EventTypeEnumFromJSON(json: any): EventTypeEnum {
    return EventTypeEnumFromJSONTyped(json, false);
}

export function EventTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTypeEnum {
    return json as EventTypeEnum;
}

export function EventTypeEnumToJSON(value?: EventTypeEnum | null): any {
    return value as any;
}

