/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotNestedPublicRoom,
  PaginatedNotNestedPublicRoomList,
  PatchedNotNestedPublicRoom,
} from '../models/index';
import {
    NotNestedPublicRoomFromJSON,
    NotNestedPublicRoomToJSON,
    PaginatedNotNestedPublicRoomListFromJSON,
    PaginatedNotNestedPublicRoomListToJSON,
    PatchedNotNestedPublicRoomFromJSON,
    PatchedNotNestedPublicRoomToJSON,
} from '../models/index';

export interface MeetingsRoomsCreateRequest {
    meetingUuid: string;
    notNestedPublicRoom: Omit<NotNestedPublicRoom, 'id'|'meeting'>;
}

export interface MeetingsRoomsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsRoomsListRequest {
    meetingUuid: string;
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
}

export interface MeetingsRoomsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedPublicRoom?: Omit<PatchedNotNestedPublicRoom, 'id'|'meeting'>;
}

export interface MeetingsRoomsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsRoomsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedPublicRoom: Omit<NotNestedPublicRoom, 'id'|'meeting'>;
}

/**
 * 
 */
export class RoomsApi extends runtime.BaseAPI {

    /**
     * Crée une nouvelle salle
     */
    async meetingsRoomsCreateRaw(requestParameters: MeetingsRoomsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicRoom>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsCreate().'
            );
        }

        if (requestParameters['notNestedPublicRoom'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicRoom',
                'Required parameter "notNestedPublicRoom" was null or undefined when calling meetingsRoomsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicRoomToJSON(requestParameters['notNestedPublicRoom']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicRoomFromJSON(jsonValue));
    }

    /**
     * Crée une nouvelle salle
     */
    async meetingsRoomsCreate(requestParameters: MeetingsRoomsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicRoom> {
        const response = await this.meetingsRoomsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supprime une salle
     */
    async meetingsRoomsDestroyRaw(requestParameters: MeetingsRoomsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRoomsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprime une salle
     */
    async meetingsRoomsDestroy(requestParameters: MeetingsRoomsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsRoomsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Liste les salles associées à un \'meeting\'
     */
    async meetingsRoomsListRaw(requestParameters: MeetingsRoomsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotNestedPublicRoomList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotNestedPublicRoomListFromJSON(jsonValue));
    }

    /**
     * Liste les salles associées à un \'meeting\'
     */
    async meetingsRoomsList(requestParameters: MeetingsRoomsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotNestedPublicRoomList> {
        const response = await this.meetingsRoomsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Modifie partiellement une salle
     */
    async meetingsRoomsPartialUpdateRaw(requestParameters: MeetingsRoomsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicRoom>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRoomsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedPublicRoomToJSON(requestParameters['patchedNotNestedPublicRoom']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicRoomFromJSON(jsonValue));
    }

    /**
     * Modifie partiellement une salle
     */
    async meetingsRoomsPartialUpdate(requestParameters: MeetingsRoomsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicRoom> {
        const response = await this.meetingsRoomsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Détaille une salle
     */
    async meetingsRoomsRetrieveRaw(requestParameters: MeetingsRoomsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicRoom>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRoomsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicRoomFromJSON(jsonValue));
    }

    /**
     * Détaille une salle
     */
    async meetingsRoomsRetrieve(requestParameters: MeetingsRoomsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicRoom> {
        const response = await this.meetingsRoomsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Modifie une salle
     */
    async meetingsRoomsUpdateRaw(requestParameters: MeetingsRoomsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicRoom>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsRoomsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRoomsUpdate().'
            );
        }

        if (requestParameters['notNestedPublicRoom'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicRoom',
                'Required parameter "notNestedPublicRoom" was null or undefined when calling meetingsRoomsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/rooms/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicRoomToJSON(requestParameters['notNestedPublicRoom']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicRoomFromJSON(jsonValue));
    }

    /**
     * Modifie une salle
     */
    async meetingsRoomsUpdate(requestParameters: MeetingsRoomsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicRoom> {
        const response = await this.meetingsRoomsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
