/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface ReadonlyStructure
 */
export interface ReadonlyStructure {
    /**
     * 
     * @type {string}
     * @memberof ReadonlyStructure
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ReadonlyStructure
     */
    readonly label: string;
    /**
     * 
     * @type {string}
     * @memberof ReadonlyStructure
     */
    readonly structure_code: string;
    /**
     * 
     * @type {string}
     * @memberof ReadonlyStructure
     */
    readonly structure_type: string;
    /**
     * 
     * @type {string}
     * @memberof ReadonlyStructure
     */
    readonly structure_echelon: string;
}

/**
 * Check if a given object implements the ReadonlyStructure interface.
 */
export function instanceOfReadonlyStructure(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('structure_code' in value)) return false;
    if (!('structure_type' in value)) return false;
    if (!('structure_echelon' in value)) return false;
    return true;
}

export function ReadonlyStructureFromJSON(json: any): ReadonlyStructure {
    return ReadonlyStructureFromJSONTyped(json, false);
}

export function ReadonlyStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadonlyStructure {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'structure_code': json['structure_code'],
        'structure_type': json['structure_type'],
        'structure_echelon': json['structure_echelon'],
    };
}

export function ReadonlyStructureToJSON(value?: Omit<ReadonlyStructure, 'id'|'label'|'structure_code'|'structure_type'|'structure_echelon'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

