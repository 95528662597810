/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NestedByStructureParticipant
 */
export interface NestedByStructureParticipant {
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly id: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    num_bafa_bafd?: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly username: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly meeting_id: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly meeting_label: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly meeting_type_id: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly meeting_type_label: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly structure_principale_id: string;
    /**
     * 
     * @type {string}
     * @memberof NestedByStructureParticipant
     */
    readonly structure_principale_label: string;
}

/**
 * Check if a given object implements the NestedByStructureParticipant interface.
 */
export function instanceOfNestedByStructureParticipant(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('user_id' in value)) return false;
    if (!('first_name' in value)) return false;
    if (!('last_name' in value)) return false;
    if (!('username' in value)) return false;
    if (!('meeting_id' in value)) return false;
    if (!('meeting_label' in value)) return false;
    if (!('meeting_type_id' in value)) return false;
    if (!('meeting_type_label' in value)) return false;
    if (!('structure_principale_id' in value)) return false;
    if (!('structure_principale_label' in value)) return false;
    return true;
}

export function NestedByStructureParticipantFromJSON(json: any): NestedByStructureParticipant {
    return NestedByStructureParticipantFromJSONTyped(json, false);
}

export function NestedByStructureParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): NestedByStructureParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'num_bafa_bafd': json['num_bafa_bafd'] == null ? undefined : json['num_bafa_bafd'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'username': json['username'],
        'meeting_id': json['meeting_id'],
        'meeting_label': json['meeting_label'],
        'meeting_type_id': json['meeting_type_id'],
        'meeting_type_label': json['meeting_type_label'],
        'structure_principale_id': json['structure_principale_id'],
        'structure_principale_label': json['structure_principale_label'],
    };
}

export function NestedByStructureParticipantToJSON(value?: Omit<NestedByStructureParticipant, 'id'|'user_id'|'first_name'|'last_name'|'username'|'meeting_id'|'meeting_label'|'meeting_type_id'|'meeting_type_label'|'structure_principale_id'|'structure_principale_label'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'num_bafa_bafd': value['num_bafa_bafd'],
    };
}

