export function variantToNotificationType(
  variant: "primary" | "success" | "neutral" | "warning" | "danger"
) {
  switch (variant) {
    case "primary":
      return NotificationType.Primary;
    case "success":
      return NotificationType.Success;
    case "neutral":
      return NotificationType.Neutral;
    case "warning":
      return NotificationType.Warning;
    case "danger":
      return NotificationType.Danger;
    default:
      return NotificationType.Primary;
  }
}
