/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantListDirection
 */
export interface ParticipantListDirection {
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    meeting_type: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantListDirection
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantListDirection
     */
    end_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantListDirection
     */
    date_de_debut: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantListDirection
     */
    date_de_fin: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    place: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    num_agreement_js: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    director: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    phone: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantListDirection
     */
    birthdate: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    fonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    structure_principale: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    groupe: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    territoire: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    parcours: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    choix_parcours: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    num_bafa_bafd: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    food_regime: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    avis_prevalidation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    avis_validation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    avis_validation_gestionnaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    js_opinion: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParticipantListDirection
     */
    sgdf_opinion: string | null;
}

/**
 * Check if a given object implements the ParticipantListDirection interface.
 */
export function instanceOfParticipantListDirection(value: object): boolean {
    if (!('meeting_type' in value)) return false;
    if (!('start_date' in value)) return false;
    if (!('end_date' in value)) return false;
    if (!('date_de_debut' in value)) return false;
    if (!('date_de_fin' in value)) return false;
    if (!('place' in value)) return false;
    if (!('num_agreement_js' in value)) return false;
    if (!('director' in value)) return false;
    if (!('lastname' in value)) return false;
    if (!('firstname' in value)) return false;
    if (!('email' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('birthdate' in value)) return false;
    if (!('fonction' in value)) return false;
    if (!('structure_principale' in value)) return false;
    if (!('groupe' in value)) return false;
    if (!('territoire' in value)) return false;
    if (!('parcours' in value)) return false;
    if (!('choix_parcours' in value)) return false;
    if (!('num_bafa_bafd' in value)) return false;
    if (!('food_regime' in value)) return false;
    if (!('avis_prevalidation' in value)) return false;
    if (!('avis_validation' in value)) return false;
    if (!('avis_validation_gestionnaire' in value)) return false;
    if (!('js_opinion' in value)) return false;
    if (!('sgdf_opinion' in value)) return false;
    return true;
}

export function ParticipantListDirectionFromJSON(json: any): ParticipantListDirection {
    return ParticipantListDirectionFromJSONTyped(json, false);
}

export function ParticipantListDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantListDirection {
    if (json == null) {
        return json;
    }
    return {
        
        'meeting_type': json['meeting_type'],
        'start_date': (new Date(json['start_date'])),
        'end_date': (new Date(json['end_date'])),
        'date_de_debut': (new Date(json['date_de_debut'])),
        'date_de_fin': (new Date(json['date_de_fin'])),
        'place': json['place'],
        'num_agreement_js': json['num_agreement_js'],
        'director': json['director'],
        'lastname': json['lastname'],
        'firstname': json['firstname'],
        'email': json['email'],
        'phone': json['phone'],
        'birthdate': (new Date(json['birthdate'])),
        'fonction': json['fonction'],
        'structure_principale': json['structure_principale'],
        'groupe': json['groupe'],
        'territoire': json['territoire'],
        'parcours': json['parcours'],
        'choix_parcours': json['choix_parcours'],
        'num_bafa_bafd': json['num_bafa_bafd'],
        'food_regime': json['food_regime'],
        'avis_prevalidation': json['avis_prevalidation'],
        'avis_validation': json['avis_validation'],
        'avis_validation_gestionnaire': json['avis_validation_gestionnaire'],
        'js_opinion': json['js_opinion'],
        'sgdf_opinion': json['sgdf_opinion'],
    };
}

export function ParticipantListDirectionToJSON(value?: ParticipantListDirection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'meeting_type': value['meeting_type'],
        'start_date': ((value['start_date']).toISOString()),
        'end_date': ((value['end_date']).toISOString()),
        'date_de_debut': ((value['date_de_debut']).toISOString()),
        'date_de_fin': ((value['date_de_fin']).toISOString()),
        'place': value['place'],
        'num_agreement_js': value['num_agreement_js'],
        'director': value['director'],
        'lastname': value['lastname'],
        'firstname': value['firstname'],
        'email': value['email'],
        'phone': value['phone'],
        'birthdate': ((value['birthdate']).toISOString().substring(0,10)),
        'fonction': value['fonction'],
        'structure_principale': value['structure_principale'],
        'groupe': value['groupe'],
        'territoire': value['territoire'],
        'parcours': value['parcours'],
        'choix_parcours': value['choix_parcours'],
        'num_bafa_bafd': value['num_bafa_bafd'],
        'food_regime': value['food_regime'],
        'avis_prevalidation': value['avis_prevalidation'],
        'avis_validation': value['avis_validation'],
        'avis_validation_gestionnaire': value['avis_validation_gestionnaire'],
        'js_opinion': value['js_opinion'],
        'sgdf_opinion': value['sgdf_opinion'],
    };
}

