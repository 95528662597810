/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Author of the comment when done with Digiform
 * @export
 * @interface MeetingCommentAuthor
 */
export interface MeetingCommentAuthor {
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly usage_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentAuthor
     */
    readonly usage_last_name: string;
}

/**
 * Check if a given object implements the MeetingCommentAuthor interface.
 */
export function instanceOfMeetingCommentAuthor(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('user_id' in value)) return false;
    if (!('first_name' in value)) return false;
    if (!('last_name' in value)) return false;
    if (!('usage_first_name' in value)) return false;
    if (!('usage_last_name' in value)) return false;
    return true;
}

export function MeetingCommentAuthorFromJSON(json: any): MeetingCommentAuthor {
    return MeetingCommentAuthorFromJSONTyped(json, false);
}

export function MeetingCommentAuthorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingCommentAuthor {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
    };
}

export function MeetingCommentAuthorToJSON(value?: Omit<MeetingCommentAuthor, 'id'|'user_id'|'first_name'|'last_name'|'usage_first_name'|'usage_last_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

