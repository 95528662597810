/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicMeetingParticipantWithAvatar } from './NotNestedPublicMeetingParticipantWithAvatar';
import {
    NotNestedPublicMeetingParticipantWithAvatarFromJSON,
    NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped,
    NotNestedPublicMeetingParticipantWithAvatarToJSON,
} from './NotNestedPublicMeetingParticipantWithAvatar';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedDailyTaskTeam
 */
export interface PatchedDailyTaskTeam {
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTaskTeam
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTaskTeam
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDailyTaskTeam
     */
    color?: string;
    /**
     * The meeting associated to the team
     * @type {string}
     * @memberof PatchedDailyTaskTeam
     */
    readonly meeting?: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipantWithAvatar>}
     * @memberof PatchedDailyTaskTeam
     */
    participants?: Array<NotNestedPublicMeetingParticipantWithAvatar>;
}

/**
 * Check if a given object implements the PatchedDailyTaskTeam interface.
 */
export function instanceOfPatchedDailyTaskTeam(value: object): boolean {
    return true;
}

export function PatchedDailyTaskTeamFromJSON(json: any): PatchedDailyTaskTeam {
    return PatchedDailyTaskTeamFromJSONTyped(json, false);
}

export function PatchedDailyTaskTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDailyTaskTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'color': json['color'] == null ? undefined : json['color'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
        'participants': json['participants'] == null ? undefined : ((json['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarFromJSON)),
    };
}

export function PatchedDailyTaskTeamToJSON(value?: Omit<PatchedDailyTaskTeam, 'id'|'meeting'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'color': value['color'],
        'participants': value['participants'] == null ? undefined : ((value['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarToJSON)),
    };
}

