import revive_payload_client_hjUgVNrkeY from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RbNqT4gokA from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_m5PrjvMEsX from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EBCzChCrFS from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_Vy5hPrzNaJ from "/app/common/temp/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.3.7/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_mTFbdB0xrm from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_gMokGDnuzv from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_11ae1SMCVb from "/app/common/temp/node_modules/.pnpm/nuxt@3.8.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import api_service_XSI3K6oOsN from "/app/webapp/plugins/api.service.ts";
import error_ldt3PQauZ0 from "/app/webapp/plugins/error.ts";
import i18n_VfGcjrvSkj from "/app/webapp/plugins/i18n.ts";
import internet_connection_qmNk5ogxp5 from "/app/webapp/plugins/internet-connection.ts";
import sentry_3AyO8nEfhE from "/app/webapp/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/app/webapp/plugins/vue-query.ts";
export default [
  revive_payload_client_hjUgVNrkeY,
  unhead_RbNqT4gokA,
  router_m5PrjvMEsX,
  payload_client_EBCzChCrFS,
  plugin_vue3_Vy5hPrzNaJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mTFbdB0xrm,
  chunk_reload_client_gMokGDnuzv,
  check_outdated_build_client_11ae1SMCVb,
  api_service_XSI3K6oOsN,
  error_ldt3PQauZ0,
  i18n_VfGcjrvSkj,
  internet_connection_qmNk5ogxp5,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe
]