/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pv
 */
export interface Pv {
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    meeting_type: string;
    /**
     * 
     * @type {Date}
     * @memberof Pv
     */
    date_de_debut: Date;
    /**
     * 
     * @type {Date}
     * @memberof Pv
     */
    date_de_fin: Date;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    place: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    num_agreement_js: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    director: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    firstname: string;
    /**
     * 
     * @type {Date}
     * @memberof Pv
     */
    birthdate: Date;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    fonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    structure_principale: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    groupe: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    territoire: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    parcours: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    choix_parcours: string;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    num_bafa_bafd: string;
    /**
     * 
     * @type {boolean}
     * @memberof Pv
     */
    session_favorable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pv
     */
    session_defavorable: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    js_opinion: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    sgdf_opinion: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    nom_dt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    prenom_dt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    num_adh_dt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    mail_dt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    nom_rg: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    prenom_rg: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    num_adh_rg: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pv
     */
    mail_rg: string | null;
}

/**
 * Check if a given object implements the Pv interface.
 */
export function instanceOfPv(value: object): boolean {
    if (!('meeting_type' in value)) return false;
    if (!('date_de_debut' in value)) return false;
    if (!('date_de_fin' in value)) return false;
    if (!('place' in value)) return false;
    if (!('num_agreement_js' in value)) return false;
    if (!('director' in value)) return false;
    if (!('lastname' in value)) return false;
    if (!('firstname' in value)) return false;
    if (!('birthdate' in value)) return false;
    if (!('fonction' in value)) return false;
    if (!('structure_principale' in value)) return false;
    if (!('groupe' in value)) return false;
    if (!('territoire' in value)) return false;
    if (!('parcours' in value)) return false;
    if (!('choix_parcours' in value)) return false;
    if (!('num_bafa_bafd' in value)) return false;
    if (!('session_favorable' in value)) return false;
    if (!('session_defavorable' in value)) return false;
    if (!('js_opinion' in value)) return false;
    if (!('sgdf_opinion' in value)) return false;
    if (!('nom_dt' in value)) return false;
    if (!('prenom_dt' in value)) return false;
    if (!('num_adh_dt' in value)) return false;
    if (!('mail_dt' in value)) return false;
    if (!('nom_rg' in value)) return false;
    if (!('prenom_rg' in value)) return false;
    if (!('num_adh_rg' in value)) return false;
    if (!('mail_rg' in value)) return false;
    return true;
}

export function PvFromJSON(json: any): Pv {
    return PvFromJSONTyped(json, false);
}

export function PvFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pv {
    if (json == null) {
        return json;
    }
    return {
        
        'meeting_type': json['meeting_type'],
        'date_de_debut': (new Date(json['date_de_debut'])),
        'date_de_fin': (new Date(json['date_de_fin'])),
        'place': json['place'],
        'num_agreement_js': json['num_agreement_js'],
        'director': json['director'],
        'lastname': json['lastname'],
        'firstname': json['firstname'],
        'birthdate': (new Date(json['birthdate'])),
        'fonction': json['fonction'],
        'structure_principale': json['structure_principale'],
        'groupe': json['groupe'],
        'territoire': json['territoire'],
        'parcours': json['parcours'],
        'choix_parcours': json['choix_parcours'],
        'num_bafa_bafd': json['num_bafa_bafd'],
        'session_favorable': json['session_favorable'],
        'session_defavorable': json['session_defavorable'],
        'js_opinion': json['js_opinion'],
        'sgdf_opinion': json['sgdf_opinion'],
        'nom_dt': json['nom_dt'],
        'prenom_dt': json['prenom_dt'],
        'num_adh_dt': json['num_adh_dt'],
        'mail_dt': json['mail_dt'],
        'nom_rg': json['nom_rg'],
        'prenom_rg': json['prenom_rg'],
        'num_adh_rg': json['num_adh_rg'],
        'mail_rg': json['mail_rg'],
    };
}

export function PvToJSON(value?: Pv | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'meeting_type': value['meeting_type'],
        'date_de_debut': ((value['date_de_debut']).toISOString()),
        'date_de_fin': ((value['date_de_fin']).toISOString()),
        'place': value['place'],
        'num_agreement_js': value['num_agreement_js'],
        'director': value['director'],
        'lastname': value['lastname'],
        'firstname': value['firstname'],
        'birthdate': ((value['birthdate']).toISOString().substring(0,10)),
        'fonction': value['fonction'],
        'structure_principale': value['structure_principale'],
        'groupe': value['groupe'],
        'territoire': value['territoire'],
        'parcours': value['parcours'],
        'choix_parcours': value['choix_parcours'],
        'num_bafa_bafd': value['num_bafa_bafd'],
        'session_favorable': value['session_favorable'],
        'session_defavorable': value['session_defavorable'],
        'js_opinion': value['js_opinion'],
        'sgdf_opinion': value['sgdf_opinion'],
        'nom_dt': value['nom_dt'],
        'prenom_dt': value['prenom_dt'],
        'num_adh_dt': value['num_adh_dt'],
        'mail_dt': value['mail_dt'],
        'nom_rg': value['nom_rg'],
        'prenom_rg': value['prenom_rg'],
        'num_adh_rg': value['num_adh_rg'],
        'mail_rg': value['mail_rg'],
    };
}

