<template>
  <div
    class="fixed flex flex-col flex-reverse items-end bottom-0 right-0 p-4 space-y-4"
  >
    <div style="z-index: 200" v-for="notification in notifications">
      <sl-alert
        :variant="notification.type"
        open
        v-if="notification.contentType === 'basic'"
      >
        <sgdf-icon slot="icon" :name="notification.icon"></sgdf-icon>
        {{ notification.message }}
      </sl-alert>

      <sl-alert
        :variant="notification.type"
        open
        v-else-if="notification.contentType === 'error'"
      >
        <sgdf-icon slot="icon" :name="notification.icon"></sgdf-icon>
        <strong>Erreur</strong><br />
        {{ notification.message }}
      </sl-alert>

      <sl-alert :variant="notification.type" open v-else>
        <sgdf-icon slot="icon" :name="notification.icon"></sgdf-icon>
        <strong>{{ notification.title }}</strong
        ><br />
        {{ notification.detail }}
      </sl-alert>
    </div>
  </div>
</template>

<script setup lang="ts">
import "@shoelace-style/shoelace/dist/components/alert/alert.js";
import "@sgdf/ui-library/sgdf-icon.js";
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button.js";

import { storeToRefs } from "pinia";
import { useNotifyStore } from "~/composables/notify-store";

const notifyStore = useNotifyStore();
const { notifications } = storeToRefs(notifyStore);
</script>
