/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password: string;
}

/**
 * Check if a given object implements the UserCreate interface.
 */
export function instanceOfUserCreate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('password' in value)) return false;
    return true;
}

export function UserCreateFromJSON(json: any): UserCreate {
    return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'id': json['id'],
        'password': json['password'],
    };
}

export function UserCreateToJSON(value?: Omit<UserCreate, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

