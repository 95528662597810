/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DailyTask,
  PaginatedDailyTaskList,
  PatchedDailyTask,
  PatchedTeamDailyTask,
  TeamDailyTask,
} from '../models/index';
import {
    DailyTaskFromJSON,
    DailyTaskToJSON,
    PaginatedDailyTaskListFromJSON,
    PaginatedDailyTaskListToJSON,
    PatchedDailyTaskFromJSON,
    PatchedDailyTaskToJSON,
    PatchedTeamDailyTaskFromJSON,
    PatchedTeamDailyTaskToJSON,
    TeamDailyTaskFromJSON,
    TeamDailyTaskToJSON,
} from '../models/index';

export interface MeetingsDailyTasksCreateRequest {
    meetingUuid: string;
    dailyTask: Omit<DailyTask, 'id'|'meeting'>;
}

export interface MeetingsDailyTasksDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsDailyTasksListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsDailyTasksMyDailyTasksListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsDailyTasksPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedDailyTask?: Omit<PatchedDailyTask, 'id'|'meeting'>;
}

export interface MeetingsDailyTasksRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsDailyTasksUpdateRequest {
    meetingUuid: string;
    uuid: string;
    dailyTask: Omit<DailyTask, 'id'|'meeting'>;
}

export interface MeetingsTeamDailyTasksCreateRequest {
    meetingUuid: string;
    teamDailyTask: Omit<TeamDailyTask, 'id'>;
}

export interface MeetingsTeamDailyTasksDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamDailyTasksListRequest {
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsTeamDailyTasksPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedTeamDailyTask?: Omit<PatchedTeamDailyTask, 'id'>;
}

export interface MeetingsTeamDailyTasksRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTeamDailyTasksUpdateRequest {
    meetingUuid: string;
    uuid: string;
    teamDailyTask: Omit<TeamDailyTask, 'id'>;
}

/**
 * 
 */
export class TasksApi extends runtime.BaseAPI {

    /**
     * Crée une tache de Service
     */
    async meetingsDailyTasksCreateRaw(requestParameters: MeetingsDailyTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksCreate().'
            );
        }

        if (requestParameters['dailyTask'] == null) {
            throw new runtime.RequiredError(
                'dailyTask',
                'Required parameter "dailyTask" was null or undefined when calling meetingsDailyTasksCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DailyTaskToJSON(requestParameters['dailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskFromJSON(jsonValue));
    }

    /**
     * Crée une tache de Service
     */
    async meetingsDailyTasksCreate(requestParameters: MeetingsDailyTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTask> {
        const response = await this.meetingsDailyTasksCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supprime une instance de Service via son ID
     */
    async meetingsDailyTasksDestroyRaw(requestParameters: MeetingsDailyTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTasksDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprime une instance de Service via son ID
     */
    async meetingsDailyTasksDestroy(requestParameters: MeetingsDailyTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsDailyTasksDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Retourne la liste des Services pour une Formation
     */
    async meetingsDailyTasksListRaw(requestParameters: MeetingsDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDailyTaskList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDailyTaskListFromJSON(jsonValue));
    }

    /**
     * Retourne la liste des Services pour une Formation
     */
    async meetingsDailyTasksList(requestParameters: MeetingsDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDailyTaskList> {
        const response = await this.meetingsDailyTasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste des taches quotidiennes du jour pour le participant
     */
    async meetingsDailyTasksMyDailyTasksListRaw(requestParameters: MeetingsDailyTasksMyDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDailyTaskList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksMyDailyTasksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/my-daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDailyTaskListFromJSON(jsonValue));
    }

    /**
     * Liste des taches quotidiennes du jour pour le participant
     */
    async meetingsDailyTasksMyDailyTasksList(requestParameters: MeetingsDailyTasksMyDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDailyTaskList> {
        const response = await this.meetingsDailyTasksMyDailyTasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch une instance de Service via son ID
     */
    async meetingsDailyTasksPartialUpdateRaw(requestParameters: MeetingsDailyTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTasksPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDailyTaskToJSON(requestParameters['patchedDailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskFromJSON(jsonValue));
    }

    /**
     * Patch une instance de Service via son ID
     */
    async meetingsDailyTasksPartialUpdate(requestParameters: MeetingsDailyTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTask> {
        const response = await this.meetingsDailyTasksPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère les informations d\'une instance de Service pour une Formation via son ID.
     */
    async meetingsDailyTasksRetrieveRaw(requestParameters: MeetingsDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskFromJSON(jsonValue));
    }

    /**
     * Récupère les informations d\'une instance de Service pour une Formation via son ID.
     */
    async meetingsDailyTasksRetrieve(requestParameters: MeetingsDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTask> {
        const response = await this.meetingsDailyTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Modifie une instance de Service pour une Formation via son ID
     */
    async meetingsDailyTasksUpdateRaw(requestParameters: MeetingsDailyTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsDailyTasksUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsDailyTasksUpdate().'
            );
        }

        if (requestParameters['dailyTask'] == null) {
            throw new runtime.RequiredError(
                'dailyTask',
                'Required parameter "dailyTask" was null or undefined when calling meetingsDailyTasksUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DailyTaskToJSON(requestParameters['dailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyTaskFromJSON(jsonValue));
    }

    /**
     * Modifie une instance de Service pour une Formation via son ID
     */
    async meetingsDailyTasksUpdate(requestParameters: MeetingsDailyTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DailyTask> {
        const response = await this.meetingsDailyTasksUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crée une tache de Service pour une Equipe
     */
    async meetingsTeamDailyTasksCreateRaw(requestParameters: MeetingsTeamDailyTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksCreate().'
            );
        }

        if (requestParameters['teamDailyTask'] == null) {
            throw new runtime.RequiredError(
                'teamDailyTask',
                'Required parameter "teamDailyTask" was null or undefined when calling meetingsTeamDailyTasksCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamDailyTaskToJSON(requestParameters['teamDailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDailyTaskFromJSON(jsonValue));
    }

    /**
     * Crée une tache de Service pour une Equipe
     */
    async meetingsTeamDailyTasksCreate(requestParameters: MeetingsTeamDailyTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDailyTask> {
        const response = await this.meetingsTeamDailyTasksCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supprime une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksDestroyRaw(requestParameters: MeetingsTeamDailyTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamDailyTasksDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprime une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksDestroy(requestParameters: MeetingsTeamDailyTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsTeamDailyTasksDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Retourne la liste des Services assignes à une Equipe
     */
    async meetingsTeamDailyTasksListRaw(requestParameters: MeetingsTeamDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamDailyTask>>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamDailyTaskFromJSON));
    }

    /**
     * Retourne la liste des Services assignes à une Equipe
     */
    async meetingsTeamDailyTasksList(requestParameters: MeetingsTeamDailyTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamDailyTask>> {
        const response = await this.meetingsTeamDailyTasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Patch une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksPartialUpdateRaw(requestParameters: MeetingsTeamDailyTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamDailyTasksPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTeamDailyTaskToJSON(requestParameters['patchedTeamDailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDailyTaskFromJSON(jsonValue));
    }

    /**
     * Patch une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksPartialUpdate(requestParameters: MeetingsTeamDailyTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDailyTask> {
        const response = await this.meetingsTeamDailyTasksPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère les informations d\'une instance de Service assigné à une Equipe via son ID.
     */
    async meetingsTeamDailyTasksRetrieveRaw(requestParameters: MeetingsTeamDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamDailyTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDailyTaskFromJSON(jsonValue));
    }

    /**
     * Récupère les informations d\'une instance de Service assigné à une Equipe via son ID.
     */
    async meetingsTeamDailyTasksRetrieve(requestParameters: MeetingsTeamDailyTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDailyTask> {
        const response = await this.meetingsTeamDailyTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Modifie une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksUpdateRaw(requestParameters: MeetingsTeamDailyTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDailyTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTeamDailyTasksUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsTeamDailyTasksUpdate().'
            );
        }

        if (requestParameters['teamDailyTask'] == null) {
            throw new runtime.RequiredError(
                'teamDailyTask',
                'Required parameter "teamDailyTask" was null or undefined when calling meetingsTeamDailyTasksUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/team-daily-tasks/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamDailyTaskToJSON(requestParameters['teamDailyTask']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDailyTaskFromJSON(jsonValue));
    }

    /**
     * Modifie une instance de Service d\'Equipe via son ID
     */
    async meetingsTeamDailyTasksUpdate(requestParameters: MeetingsTeamDailyTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDailyTask> {
        const response = await this.meetingsTeamDailyTasksUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
