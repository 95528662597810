/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MeetingComment,
  MeetingCommentTag,
  PaginatedMeetingCommentList,
  PatchedMeetingComment,
  PatchedMeetingCommentTag,
} from '../models/index';
import {
    MeetingCommentFromJSON,
    MeetingCommentToJSON,
    MeetingCommentTagFromJSON,
    MeetingCommentTagToJSON,
    PaginatedMeetingCommentListFromJSON,
    PaginatedMeetingCommentListToJSON,
    PatchedMeetingCommentFromJSON,
    PatchedMeetingCommentToJSON,
    PatchedMeetingCommentTagFromJSON,
    PatchedMeetingCommentTagToJSON,
} from '../models/index';

export interface MeetingsCommentTagsCreateRequest {
    meetingUuid: string;
    meetingCommentTag: Omit<MeetingCommentTag, 'id'|'meeting_id'>;
}

export interface MeetingsCommentTagsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsCommentTagsListRequest {
    meetingUuid: string;
    ordering?: string;
    search?: string;
}

export interface MeetingsCommentTagsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedMeetingCommentTag?: Omit<PatchedMeetingCommentTag, 'id'|'meeting_id'>;
}

export interface MeetingsCommentTagsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsCommentTagsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    meetingCommentTag: Omit<MeetingCommentTag, 'id'|'meeting_id'>;
}

export interface MeetingsParticipantsCommentsCreateRequest {
    meetingUuid: string;
    participantUuid: string;
    meetingComment: Omit<MeetingComment, 'id'|'participant_id'|'created_at'|'author_id'|'tags'>;
}

export interface MeetingsParticipantsCommentsDestroyRequest {
    meetingUuid: string;
    participantUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsCommentsListRequest {
    meetingUuid: string;
    participantUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsParticipantsCommentsPartialUpdateRequest {
    meetingUuid: string;
    participantUuid: string;
    uuid: string;
    patchedMeetingComment?: Omit<PatchedMeetingComment, 'id'|'participant_id'|'created_at'|'author_id'|'tags'>;
}

export interface MeetingsParticipantsCommentsRetrieveRequest {
    meetingUuid: string;
    participantUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsCommentsUpdateRequest {
    meetingUuid: string;
    participantUuid: string;
    uuid: string;
    meetingComment: Omit<MeetingComment, 'id'|'participant_id'|'created_at'|'author_id'|'tags'>;
}

/**
 * 
 */
export class CommentsApi extends runtime.BaseAPI {

    /**
     * Create a new tag for comments
     */
    async meetingsCommentTagsCreateRaw(requestParameters: MeetingsCommentTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingCommentTag>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsCreate().'
            );
        }

        if (requestParameters['meetingCommentTag'] == null) {
            throw new runtime.RequiredError(
                'meetingCommentTag',
                'Required parameter "meetingCommentTag" was null or undefined when calling meetingsCommentTagsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingCommentTagToJSON(requestParameters['meetingCommentTag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentTagFromJSON(jsonValue));
    }

    /**
     * Create a new tag for comments
     */
    async meetingsCommentTagsCreate(requestParameters: MeetingsCommentTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingCommentTag> {
        const response = await this.meetingsCommentTagsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a tag for comments
     */
    async meetingsCommentTagsDestroyRaw(requestParameters: MeetingsCommentTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsCommentTagsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a tag for comments
     */
    async meetingsCommentTagsDestroy(requestParameters: MeetingsCommentTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsCommentTagsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List all tags available for the comments
     */
    async meetingsCommentTagsListRaw(requestParameters: MeetingsCommentTagsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeetingCommentTag>>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingCommentTagFromJSON));
    }

    /**
     * List all tags available for the comments
     */
    async meetingsCommentTagsList(requestParameters: MeetingsCommentTagsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeetingCommentTag>> {
        const response = await this.meetingsCommentTagsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partially update a tag
     */
    async meetingsCommentTagsPartialUpdateRaw(requestParameters: MeetingsCommentTagsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingCommentTag>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsCommentTagsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingCommentTagToJSON(requestParameters['patchedMeetingCommentTag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentTagFromJSON(jsonValue));
    }

    /**
     * Partially update a tag
     */
    async meetingsCommentTagsPartialUpdate(requestParameters: MeetingsCommentTagsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingCommentTag> {
        const response = await this.meetingsCommentTagsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single tag for comments
     */
    async meetingsCommentTagsRetrieveRaw(requestParameters: MeetingsCommentTagsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingCommentTag>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsCommentTagsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentTagFromJSON(jsonValue));
    }

    /**
     * Get a single tag for comments
     */
    async meetingsCommentTagsRetrieve(requestParameters: MeetingsCommentTagsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingCommentTag> {
        const response = await this.meetingsCommentTagsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a tag
     */
    async meetingsCommentTagsUpdateRaw(requestParameters: MeetingsCommentTagsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingCommentTag>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsCommentTagsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsCommentTagsUpdate().'
            );
        }

        if (requestParameters['meetingCommentTag'] == null) {
            throw new runtime.RequiredError(
                'meetingCommentTag',
                'Required parameter "meetingCommentTag" was null or undefined when calling meetingsCommentTagsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/comment-tags/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingCommentTagToJSON(requestParameters['meetingCommentTag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentTagFromJSON(jsonValue));
    }

    /**
     * Update a tag
     */
    async meetingsCommentTagsUpdate(requestParameters: MeetingsCommentTagsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingCommentTag> {
        const response = await this.meetingsCommentTagsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new comment for a participant in a meeting
     */
    async meetingsParticipantsCommentsCreateRaw(requestParameters: MeetingsParticipantsCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingComment>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsCreate().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsCreate().'
            );
        }

        if (requestParameters['meetingComment'] == null) {
            throw new runtime.RequiredError(
                'meetingComment',
                'Required parameter "meetingComment" was null or undefined when calling meetingsParticipantsCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingCommentToJSON(requestParameters['meetingComment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentFromJSON(jsonValue));
    }

    /**
     * Create a new comment for a participant in a meeting
     */
    async meetingsParticipantsCommentsCreate(requestParameters: MeetingsParticipantsCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingComment> {
        const response = await this.meetingsParticipantsCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a comment for a participant
     */
    async meetingsParticipantsCommentsDestroyRaw(requestParameters: MeetingsParticipantsCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsDestroy().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a comment for a participant
     */
    async meetingsParticipantsCommentsDestroy(requestParameters: MeetingsParticipantsCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsParticipantsCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List all comments for a single participant for a single meeting
     */
    async meetingsParticipantsCommentsListRaw(requestParameters: MeetingsParticipantsCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingCommentList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsList().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingCommentListFromJSON(jsonValue));
    }

    /**
     * List all comments for a single participant for a single meeting
     */
    async meetingsParticipantsCommentsList(requestParameters: MeetingsParticipantsCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingCommentList> {
        const response = await this.meetingsParticipantsCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partially update a comment for a participant
     */
    async meetingsParticipantsCommentsPartialUpdateRaw(requestParameters: MeetingsParticipantsCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingComment>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsPartialUpdate().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingCommentToJSON(requestParameters['patchedMeetingComment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentFromJSON(jsonValue));
    }

    /**
     * Partially update a comment for a participant
     */
    async meetingsParticipantsCommentsPartialUpdate(requestParameters: MeetingsParticipantsCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingComment> {
        const response = await this.meetingsParticipantsCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single comment for a participant
     */
    async meetingsParticipantsCommentsRetrieveRaw(requestParameters: MeetingsParticipantsCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingComment>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsRetrieve().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentFromJSON(jsonValue));
    }

    /**
     * Get a single comment for a participant
     */
    async meetingsParticipantsCommentsRetrieve(requestParameters: MeetingsParticipantsCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingComment> {
        const response = await this.meetingsParticipantsCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a comment for a participant
     */
    async meetingsParticipantsCommentsUpdateRaw(requestParameters: MeetingsParticipantsCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingComment>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsCommentsUpdate().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling meetingsParticipantsCommentsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsCommentsUpdate().'
            );
        }

        if (requestParameters['meetingComment'] == null) {
            throw new runtime.RequiredError(
                'meetingComment',
                'Required parameter "meetingComment" was null or undefined when calling meetingsParticipantsCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{participant_uuid}/comments/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingCommentToJSON(requestParameters['meetingComment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingCommentFromJSON(jsonValue));
    }

    /**
     * Update a comment for a participant
     */
    async meetingsParticipantsCommentsUpdate(requestParameters: MeetingsParticipantsCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingComment> {
        const response = await this.meetingsParticipantsCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
