/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingModule } from './MeetingModule';
import {
    MeetingModuleFromJSON,
    MeetingModuleFromJSONTyped,
    MeetingModuleToJSON,
} from './MeetingModule';
import type { MeetingType } from './MeetingType';
import {
    MeetingTypeFromJSON,
    MeetingTypeFromJSONTyped,
    MeetingTypeToJSON,
} from './MeetingType';
import type { NotNestedPlace } from './NotNestedPlace';
import {
    NotNestedPlaceFromJSON,
    NotNestedPlaceFromJSONTyped,
    NotNestedPlaceToJSON,
} from './NotNestedPlace';
import type { NotNestedPublicMeetingParticipant } from './NotNestedPublicMeetingParticipant';
import {
    NotNestedPublicMeetingParticipantFromJSON,
    NotNestedPublicMeetingParticipantFromJSONTyped,
    NotNestedPublicMeetingParticipantToJSON,
} from './NotNestedPublicMeetingParticipant';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';
import type { StructureType } from './StructureType';
import {
    StructureTypeFromJSON,
    StructureTypeFromJSONTyped,
    StructureTypeToJSON,
} from './StructureType';
import type { TagadaStatusEnum } from './TagadaStatusEnum';
import {
    TagadaStatusEnumFromJSON,
    TagadaStatusEnumFromJSONTyped,
    TagadaStatusEnumToJSON,
} from './TagadaStatusEnum';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingGestionnaire
 */
export interface MeetingGestionnaire {
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly label: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly end_date: Date;
    /**
     * 
     * @type {MeetingType}
     * @memberof MeetingGestionnaire
     */
    readonly meeting_type: MeetingType;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly meeting_type_id: string | null;
    /**
     * 
     * @type {NotNestedPlace}
     * @memberof MeetingGestionnaire
     */
    readonly place: NotNestedPlace;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly place_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly start_date: Date;
    /**
     * 
     * @type {StructureType}
     * @memberof MeetingGestionnaire
     */
    readonly structure_organisatrice: StructureType;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly structure_organisatrice_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly updated_at: Date;
    /**
     * 
     * @type {Array<MeetingModule>}
     * @memberof MeetingGestionnaire
     */
    modules: Array<MeetingModule>;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly daytime_min: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly daytime_max: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    code_analytic?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    readonly intranet_id: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipant>}
     * @memberof MeetingGestionnaire
     */
    supervisors: Array<NotNestedPublicMeetingParticipant>;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    num_agreement_js?: string;
    /**
     * Description du stage
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    description?: string;
    /**
     * 
     * @type {StatusEnum}
     * @memberof MeetingGestionnaire
     */
    readonly status: StatusEnum;
    /**
     * 
     * @type {Array<Track>}
     * @memberof MeetingGestionnaire
     */
    readonly tracks: Array<Track>;
    /**
     * 
     * @type {string}
     * @memberof MeetingGestionnaire
     */
    tagada_password?: string;
    /**
     * 
     * @type {TagadaStatusEnum}
     * @memberof MeetingGestionnaire
     */
    readonly tagada_status: TagadaStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly tagada_participants_last_sync: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly tagada_avis_last_sync: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MeetingGestionnaire
     */
    tagada_total_candidates: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingGestionnaire
     */
    tagada_synced_candidates: number;
    /**
     * 
     * @type {Date}
     * @memberof MeetingGestionnaire
     */
    readonly tagada_participants_sync_lock: Date | null;
}

/**
 * Check if a given object implements the MeetingGestionnaire interface.
 */
export function instanceOfMeetingGestionnaire(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('created_at' in value)) return false;
    if (!('end_date' in value)) return false;
    if (!('meeting_type' in value)) return false;
    if (!('meeting_type_id' in value)) return false;
    if (!('place' in value)) return false;
    if (!('place_id' in value)) return false;
    if (!('start_date' in value)) return false;
    if (!('structure_organisatrice' in value)) return false;
    if (!('structure_organisatrice_id' in value)) return false;
    if (!('updated_at' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('daytime_min' in value)) return false;
    if (!('daytime_max' in value)) return false;
    if (!('intranet_id' in value)) return false;
    if (!('supervisors' in value)) return false;
    if (!('status' in value)) return false;
    if (!('tracks' in value)) return false;
    if (!('tagada_status' in value)) return false;
    if (!('tagada_participants_last_sync' in value)) return false;
    if (!('tagada_avis_last_sync' in value)) return false;
    if (!('tagada_total_candidates' in value)) return false;
    if (!('tagada_synced_candidates' in value)) return false;
    if (!('tagada_participants_sync_lock' in value)) return false;
    return true;
}

export function MeetingGestionnaireFromJSON(json: any): MeetingGestionnaire {
    return MeetingGestionnaireFromJSONTyped(json, false);
}

export function MeetingGestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingGestionnaire {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'created_at': (new Date(json['created_at'])),
        'end_date': (new Date(json['end_date'])),
        'meeting_type': MeetingTypeFromJSON(json['meeting_type']),
        'meeting_type_id': json['meeting_type_id'],
        'place': NotNestedPlaceFromJSON(json['place']),
        'place_id': json['place_id'],
        'start_date': (new Date(json['start_date'])),
        'structure_organisatrice': StructureTypeFromJSON(json['structure_organisatrice']),
        'structure_organisatrice_id': json['structure_organisatrice_id'],
        'updated_at': (new Date(json['updated_at'])),
        'modules': ((json['modules'] as Array<any>).map(MeetingModuleFromJSON)),
        'daytime_min': json['daytime_min'],
        'daytime_max': json['daytime_max'],
        'code_analytic': json['code_analytic'] == null ? undefined : json['code_analytic'],
        'intranet_id': json['intranet_id'],
        'supervisors': ((json['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantFromJSON)),
        'num_agreement_js': json['num_agreement_js'] == null ? undefined : json['num_agreement_js'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': StatusEnumFromJSON(json['status']),
        'tracks': ((json['tracks'] as Array<any>).map(TrackFromJSON)),
        'tagada_password': json['tagada_password'] == null ? undefined : json['tagada_password'],
        'tagada_status': TagadaStatusEnumFromJSON(json['tagada_status']),
        'tagada_participants_last_sync': (json['tagada_participants_last_sync'] == null ? null : new Date(json['tagada_participants_last_sync'])),
        'tagada_avis_last_sync': (json['tagada_avis_last_sync'] == null ? null : new Date(json['tagada_avis_last_sync'])),
        'tagada_total_candidates': json['tagada_total_candidates'],
        'tagada_synced_candidates': json['tagada_synced_candidates'],
        'tagada_participants_sync_lock': (json['tagada_participants_sync_lock'] == null ? null : new Date(json['tagada_participants_sync_lock'])),
    };
}

export function MeetingGestionnaireToJSON(value?: Omit<MeetingGestionnaire, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modules': ((value['modules'] as Array<any>).map(MeetingModuleToJSON)),
        'code_analytic': value['code_analytic'],
        'supervisors': ((value['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantToJSON)),
        'num_agreement_js': value['num_agreement_js'],
        'description': value['description'],
        'tagada_password': value['tagada_password'],
        'tagada_total_candidates': value['tagada_total_candidates'],
        'tagada_synced_candidates': value['tagada_synced_candidates'],
    };
}

