/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingCommentAuthor } from './MeetingCommentAuthor';
import {
    MeetingCommentAuthorFromJSON,
    MeetingCommentAuthorFromJSONTyped,
    MeetingCommentAuthorToJSON,
} from './MeetingCommentAuthor';
import type { MeetingCommentTag } from './MeetingCommentTag';
import {
    MeetingCommentTagFromJSON,
    MeetingCommentTagFromJSONTyped,
    MeetingCommentTagToJSON,
} from './MeetingCommentTag';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeetingComment
 */
export interface PatchedMeetingComment {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    readonly participant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    text?: string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof PatchedMeetingComment
     */
    type?: TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeetingComment
     */
    readonly created_at?: Date;
    /**
     * Date must be filled for a comment
     * @type {Date}
     * @memberof PatchedMeetingComment
     */
    date?: Date;
    /**
     * 
     * @type {MeetingCommentAuthor}
     * @memberof PatchedMeetingComment
     */
    author?: MeetingCommentAuthor;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    readonly author_id?: string;
    /**
     * List of nested tags associated
     * @type {Array<MeetingCommentTag>}
     * @memberof PatchedMeetingComment
     */
    readonly tags?: Array<MeetingCommentTag>;
    /**
     * List of ids of the tags associated
     * @type {Array<string>}
     * @memberof PatchedMeetingComment
     */
    tags_id?: Array<string>;
    /**
     * Assign the comment as a special comment. allowed_values : (start_interview, middle_interview, end_interview, sgdf_opinion, js_opinion)
     * @type {string}
     * @memberof PatchedMeetingComment
     */
    assign_as?: string;
}

/**
 * Check if a given object implements the PatchedMeetingComment interface.
 */
export function instanceOfPatchedMeetingComment(value: object): boolean {
    return true;
}

export function PatchedMeetingCommentFromJSON(json: any): PatchedMeetingComment {
    return PatchedMeetingCommentFromJSONTyped(json, false);
}

export function PatchedMeetingCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeetingComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'event': json['event'] == null ? undefined : json['event'],
        'participant_id': json['participant_id'] == null ? undefined : json['participant_id'],
        'text': json['text'] == null ? undefined : json['text'],
        'type': json['type'] == null ? undefined : TypeEnumFromJSON(json['type']),
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'author': json['author'] == null ? undefined : MeetingCommentAuthorFromJSON(json['author']),
        'author_id': json['author_id'] == null ? undefined : json['author_id'],
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(MeetingCommentTagFromJSON)),
        'tags_id': json['tags_id'] == null ? undefined : json['tags_id'],
        'assign_as': json['assign_as'] == null ? undefined : json['assign_as'],
    };
}

export function PatchedMeetingCommentToJSON(value?: Omit<PatchedMeetingComment, 'id'|'participant_id'|'created_at'|'author_id'|'tags'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event': value['event'],
        'text': value['text'],
        'type': TypeEnumToJSON(value['type']),
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'author': MeetingCommentAuthorToJSON(value['author']),
        'tags_id': value['tags_id'],
        'assign_as': value['assign_as'],
    };
}

