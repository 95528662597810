import { useNotifyStore } from "~/composables/notify-store";

export default defineNuxtPlugin(() => {
  const { notify } = useNotifyStore();
  window.addEventListener("online", () =>
    notify(
      "Vous êtes de nouveau connecté à internet",
      NotificationType.Success,
      "wifi",
      2000
    )
  );
  window.addEventListener("offline", () =>
    notify(
      "Vous êtes déconnecté d'internet",
      NotificationType.Danger,
      "wifi",
      4000
    )
  );
});
