/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPublicActivity
 */
export interface NotNestedPublicActivity {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivity
     */
    readonly id: string;
    /**
     * Must not be null, an Activity must be linked to a meeting
     * @type {string}
     * @memberof NotNestedPublicActivity
     */
    readonly meeting_id: string;
    /**
     * Event Category, must be filled to create an event
     * @type {string}
     * @memberof NotNestedPublicActivity
     */
    category?: string;
    /**
     * Une Activity peut être associé à zéro, un ou plusieurs Track (parcours) 
     * @type {Array<string>}
     * @memberof NotNestedPublicActivity
     */
    tracks?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivity
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPublicActivity
     */
    description?: string;
    /**
     * Nombre maximum de participants autorisés lors de cette Activity
     * @type {number}
     * @memberof NotNestedPublicActivity
     */
    max_participants?: number;
}

/**
 * Check if a given object implements the NotNestedPublicActivity interface.
 */
export function instanceOfNotNestedPublicActivity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('meeting_id' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function NotNestedPublicActivityFromJSON(json: any): NotNestedPublicActivity {
    return NotNestedPublicActivityFromJSONTyped(json, false);
}

export function NotNestedPublicActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPublicActivity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'meeting_id': json['meeting_id'],
        'category': json['category'] == null ? undefined : json['category'],
        'tracks': json['tracks'] == null ? undefined : json['tracks'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'max_participants': json['max_participants'] == null ? undefined : json['max_participants'],
    };
}

export function NotNestedPublicActivityToJSON(value?: Omit<NotNestedPublicActivity, 'id'|'meeting_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'category': value['category'],
        'tracks': value['tracks'],
        'label': value['label'],
        'description': value['description'],
        'max_participants': value['max_participants'],
    };
}

