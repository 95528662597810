/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrancheEnum } from './BrancheEnum';
import {
    BrancheEnumFromJSON,
    BrancheEnumFromJSONTyped,
    BrancheEnumToJSON,
} from './BrancheEnum';
import type { NotNestedDailyTaskTeam } from './NotNestedDailyTaskTeam';
import {
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamFromJSONTyped,
    NotNestedDailyTaskTeamToJSON,
} from './NotNestedDailyTaskTeam';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
} from './NotNestedMeetingRole';
import type { NotNestedTeam } from './NotNestedTeam';
import {
    NotNestedTeamFromJSON,
    NotNestedTeamFromJSONTyped,
    NotNestedTeamToJSON,
} from './NotNestedTeam';
import type { NotNestedUser } from './NotNestedUser';
import {
    NotNestedUserFromJSON,
    NotNestedUserFromJSONTyped,
    NotNestedUserToJSON,
} from './NotNestedUser';
import type { ReadonlyStructure } from './ReadonlyStructure';
import {
    ReadonlyStructureFromJSON,
    ReadonlyStructureFromJSONTyped,
    ReadonlyStructureToJSON,
} from './ReadonlyStructure';
import type { SessionStatusEnum } from './SessionStatusEnum';
import {
    SessionStatusEnumFromJSON,
    SessionStatusEnumFromJSONTyped,
    SessionStatusEnumToJSON,
} from './SessionStatusEnum';
import type { TagadaAvisStatusEnum } from './TagadaAvisStatusEnum';
import {
    TagadaAvisStatusEnumFromJSON,
    TagadaAvisStatusEnumFromJSONTyped,
    TagadaAvisStatusEnumToJSON,
} from './TagadaAvisStatusEnum';
import type { TagadaCandidateStatusEnum } from './TagadaCandidateStatusEnum';
import {
    TagadaCandidateStatusEnumFromJSON,
    TagadaCandidateStatusEnumFromJSONTyped,
    TagadaCandidateStatusEnumToJSON,
} from './TagadaCandidateStatusEnum';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingParticipant
 */
export interface MeetingParticipant {
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly user_id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    usage_first_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    usage_last_name: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    readonly updated: Date;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly meeting: string;
    /**
     * 
     * @type {NotNestedUser}
     * @memberof MeetingParticipant
     */
    readonly user: NotNestedUser;
    /**
     * 
     * @type {NotNestedMeetingRole}
     * @memberof MeetingParticipant
     */
    readonly role: NotNestedMeetingRole;
    /**
     * 
     * @type {NotNestedDailyTaskTeam}
     * @memberof MeetingParticipant
     */
    readonly daily_task_team: NotNestedDailyTaskTeam;
    /**
     * 
     * @type {Array<NotNestedTeam>}
     * @memberof MeetingParticipant
     */
    readonly teams: Array<NotNestedTeam>;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly fonction: string;
    /**
     * 
     * @type {ReadonlyStructure}
     * @memberof MeetingParticipant
     */
    readonly structure_principale: ReadonlyStructure | null;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * 
     * * `0` - Non-défini
     * * `270` - Farfadets
     * * `210` - Louveteaux-Jeannettes
     * * `220` - Scouts-Guides
     * * `230` - Pionniers-Caravelles
     * * `240` - Compagnons
     * * `290` - Audace
     * * `180` - Impeesa
     * * `310` - Équipe Groupe
     * * `300` - Responsable Groupe
     * * `600` - Équipe Territorial
     * * `505` - Responsable Territorial
     * * `500` - Délégué Territorial
     * * `900` - Équipe National
     * * `980` - Salarié
     * * `1000` - Autre branche
     * @type {BrancheEnum}
     * @memberof MeetingParticipant
     */
    branche?: BrancheEnum;
    /**
     * 
     * @type {Track}
     * @memberof MeetingParticipant
     */
    readonly track: Track;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof MeetingParticipant
     */
    num_bafa_bafd?: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof MeetingParticipant
     */
    food_regime?: string;
    /**
     * 
     * @type {SessionStatusEnum}
     * @memberof MeetingParticipant
     */
    session_status?: SessionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    complement_information?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    readonly canceled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MeetingParticipant
     */
    readonly observation_count: number;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    birthdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    hebergement?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    track_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    readonly last_sync: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    is_bafa_bafd_candidate?: boolean;
    /**
     * 
     * @type {TagadaCandidateStatusEnum}
     * @memberof MeetingParticipant
     */
    tagada_candidate_status?: TagadaCandidateStatusEnum;
    /**
     * 
     * @type {TagadaAvisStatusEnum}
     * @memberof MeetingParticipant
     */
    tagada_avis_status?: TagadaAvisStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    tagada_avis_last_sync?: Date;
    /**
     * Compte rendu de début de stage
     * @type {string}
     * @memberof MeetingParticipant
     */
    start_interview?: string;
    /**
     * Compte rendu d'entretien de mi-stage
     * @type {string}
     * @memberof MeetingParticipant
     */
    middle_interview?: string;
    /**
     * Compte rendu d'entretien de fin de stage
     * @type {string}
     * @memberof MeetingParticipant
     */
    end_interview?: string;
    /**
     * Avis interne SGdF de fin de stage
     * @type {string}
     * @memberof MeetingParticipant
     */
    sgdf_opinion?: string;
    /**
     * Avis JS de fin de stage
     * @type {string}
     * @memberof MeetingParticipant
     */
    js_opinion?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly avis_prevalidation: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly avis_validation: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly avis_validation_gestionnaire: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly signature_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    readonly signature_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    readonly avatar_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    readonly user_avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingParticipant
     */
    readonly user_avatar_available: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MeetingParticipant
     */
    user_last_sync: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    gender: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    birth_name: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    which_administrative_lastname: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    which_administrative_firstname: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    email: string;
}

/**
 * Check if a given object implements the MeetingParticipant interface.
 */
export function instanceOfMeetingParticipant(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('user_id' in value)) return false;
    if (!('first_name' in value)) return false;
    if (!('last_name' in value)) return false;
    if (!('usage_first_name' in value)) return false;
    if (!('usage_last_name' in value)) return false;
    if (!('created' in value)) return false;
    if (!('updated' in value)) return false;
    if (!('meeting' in value)) return false;
    if (!('user' in value)) return false;
    if (!('role' in value)) return false;
    if (!('daily_task_team' in value)) return false;
    if (!('teams' in value)) return false;
    if (!('fonction' in value)) return false;
    if (!('structure_principale' in value)) return false;
    if (!('track' in value)) return false;
    if (!('canceled' in value)) return false;
    if (!('observation_count' in value)) return false;
    if (!('track_id' in value)) return false;
    if (!('last_sync' in value)) return false;
    if (!('avis_prevalidation' in value)) return false;
    if (!('avis_validation' in value)) return false;
    if (!('avis_validation_gestionnaire' in value)) return false;
    if (!('signature_url' in value)) return false;
    if (!('signature_available' in value)) return false;
    if (!('avatar_url' in value)) return false;
    if (!('avatar_available' in value)) return false;
    if (!('user_avatar_url' in value)) return false;
    if (!('user_avatar_available' in value)) return false;
    if (!('user_last_sync' in value)) return false;
    if (!('gender' in value)) return false;
    if (!('birth_name' in value)) return false;
    if (!('which_administrative_lastname' in value)) return false;
    if (!('which_administrative_firstname' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('email' in value)) return false;
    return true;
}

export function MeetingParticipantFromJSON(json: any): MeetingParticipant {
    return MeetingParticipantFromJSONTyped(json, false);
}

export function MeetingParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': json['user_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'meeting': json['meeting'],
        'user': NotNestedUserFromJSON(json['user']),
        'role': NotNestedMeetingRoleFromJSON(json['role']),
        'daily_task_team': NotNestedDailyTaskTeamFromJSON(json['daily_task_team']),
        'teams': ((json['teams'] as Array<any>).map(NotNestedTeamFromJSON)),
        'fonction': json['fonction'],
        'structure_principale': ReadonlyStructureFromJSON(json['structure_principale']),
        'branche': json['branche'] == null ? undefined : BrancheEnumFromJSON(json['branche']),
        'track': TrackFromJSON(json['track']),
        'num_bafa_bafd': json['num_bafa_bafd'] == null ? undefined : json['num_bafa_bafd'],
        'food_regime': json['food_regime'] == null ? undefined : json['food_regime'],
        'session_status': json['session_status'] == null ? undefined : SessionStatusEnumFromJSON(json['session_status']),
        'complement_information': json['complement_information'] == null ? undefined : json['complement_information'],
        'canceled': json['canceled'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
        'observation_count': json['observation_count'],
        'birthdate': json['birthdate'] == null ? undefined : (new Date(json['birthdate'])),
        'hebergement': json['hebergement'] == null ? undefined : json['hebergement'],
        'track_id': json['track_id'],
        'last_sync': (json['last_sync'] == null ? null : new Date(json['last_sync'])),
        'is_bafa_bafd_candidate': json['is_bafa_bafd_candidate'] == null ? undefined : json['is_bafa_bafd_candidate'],
        'tagada_candidate_status': json['tagada_candidate_status'] == null ? undefined : TagadaCandidateStatusEnumFromJSON(json['tagada_candidate_status']),
        'tagada_avis_status': json['tagada_avis_status'] == null ? undefined : TagadaAvisStatusEnumFromJSON(json['tagada_avis_status']),
        'tagada_avis_last_sync': json['tagada_avis_last_sync'] == null ? undefined : (new Date(json['tagada_avis_last_sync'])),
        'start_interview': json['start_interview'] == null ? undefined : json['start_interview'],
        'middle_interview': json['middle_interview'] == null ? undefined : json['middle_interview'],
        'end_interview': json['end_interview'] == null ? undefined : json['end_interview'],
        'sgdf_opinion': json['sgdf_opinion'] == null ? undefined : json['sgdf_opinion'],
        'js_opinion': json['js_opinion'] == null ? undefined : json['js_opinion'],
        'avis_prevalidation': json['avis_prevalidation'],
        'avis_validation': json['avis_validation'],
        'avis_validation_gestionnaire': json['avis_validation_gestionnaire'],
        'signature_url': json['signature_url'],
        'signature_available': json['signature_available'],
        'avatar_url': json['avatar_url'],
        'avatar_available': json['avatar_available'],
        'user_avatar_url': json['user_avatar_url'],
        'user_avatar_available': json['user_avatar_available'],
        'user_last_sync': (json['user_last_sync'] == null ? null : new Date(json['user_last_sync'])),
        'gender': json['gender'],
        'birth_name': json['birth_name'],
        'which_administrative_lastname': json['which_administrative_lastname'],
        'which_administrative_firstname': json['which_administrative_firstname'],
        'phone': json['phone'],
        'email': json['email'],
    };
}

export function MeetingParticipantToJSON(value?: Omit<MeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'usage_first_name': value['usage_first_name'],
        'usage_last_name': value['usage_last_name'],
        'branche': BrancheEnumToJSON(value['branche']),
        'num_bafa_bafd': value['num_bafa_bafd'],
        'food_regime': value['food_regime'],
        'session_status': SessionStatusEnumToJSON(value['session_status']),
        'complement_information': value['complement_information'],
        'hidden': value['hidden'],
        'birthdate': value['birthdate'] == null ? undefined : ((value['birthdate'] as any).toISOString().substring(0,10)),
        'hebergement': value['hebergement'],
        'track_id': value['track_id'],
        'is_bafa_bafd_candidate': value['is_bafa_bafd_candidate'],
        'tagada_candidate_status': TagadaCandidateStatusEnumToJSON(value['tagada_candidate_status']),
        'tagada_avis_status': TagadaAvisStatusEnumToJSON(value['tagada_avis_status']),
        'tagada_avis_last_sync': value['tagada_avis_last_sync'] == null ? undefined : ((value['tagada_avis_last_sync'] as any).toISOString()),
        'start_interview': value['start_interview'],
        'middle_interview': value['middle_interview'],
        'end_interview': value['end_interview'],
        'sgdf_opinion': value['sgdf_opinion'],
        'js_opinion': value['js_opinion'],
        'user_last_sync': (value['user_last_sync'] == null ? null : (value['user_last_sync'] as any).toISOString()),
        'gender': value['gender'],
        'birth_name': value['birth_name'],
        'which_administrative_lastname': value['which_administrative_lastname'],
        'which_administrative_firstname': value['which_administrative_firstname'],
        'phone': value['phone'],
        'email': value['email'],
    };
}

