/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingType } from './MeetingType';
import {
    MeetingTypeFromJSON,
    MeetingTypeFromJSONTyped,
    MeetingTypeToJSON,
} from './MeetingType';

/**
 * 
 * @export
 * @interface PaginatedMeetingTypeList
 */
export interface PaginatedMeetingTypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMeetingTypeList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingTypeList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMeetingTypeList
     */
    previous?: string;
    /**
     * 
     * @type {Array<MeetingType>}
     * @memberof PaginatedMeetingTypeList
     */
    results: Array<MeetingType>;
}

/**
 * Check if a given object implements the PaginatedMeetingTypeList interface.
 */
export function instanceOfPaginatedMeetingTypeList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedMeetingTypeListFromJSON(json: any): PaginatedMeetingTypeList {
    return PaginatedMeetingTypeListFromJSONTyped(json, false);
}

export function PaginatedMeetingTypeListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMeetingTypeList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(MeetingTypeFromJSON)),
    };
}

export function PaginatedMeetingTypeListToJSON(value?: PaginatedMeetingTypeList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(MeetingTypeToJSON)),
    };
}

