/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingCommentTag
 */
export interface MeetingCommentTag {
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentTag
     */
    readonly id: string;
    /**
     * a CommentTag is custom to a particular meeting
     * @type {string}
     * @memberof MeetingCommentTag
     */
    readonly meeting_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentTag
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentTag
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingCommentTag
     */
    favorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingCommentTag
     */
    icon?: string;
}

/**
 * Check if a given object implements the MeetingCommentTag interface.
 */
export function instanceOfMeetingCommentTag(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('meeting_id' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function MeetingCommentTagFromJSON(json: any): MeetingCommentTag {
    return MeetingCommentTagFromJSONTyped(json, false);
}

export function MeetingCommentTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingCommentTag {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'meeting_id': json['meeting_id'],
        'color': json['color'] == null ? undefined : json['color'],
        'label': json['label'],
        'favorite': json['favorite'] == null ? undefined : json['favorite'],
        'icon': json['icon'] == null ? undefined : json['icon'],
    };
}

export function MeetingCommentTagToJSON(value?: Omit<MeetingCommentTag, 'id'|'meeting_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'color': value['color'],
        'label': value['label'],
        'favorite': value['favorite'],
        'icon': value['icon'],
    };
}

