/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedPublicTeamCategory
 */
export interface PatchedNotNestedPublicTeamCategory {
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicTeamCategory
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicTeamCategory
     */
    label?: string;
    /**
     * The category is custom to a particular meeting
     * @type {string}
     * @memberof PatchedNotNestedPublicTeamCategory
     */
    readonly meeting?: string;
}

/**
 * Check if a given object implements the PatchedNotNestedPublicTeamCategory interface.
 */
export function instanceOfPatchedNotNestedPublicTeamCategory(value: object): boolean {
    return true;
}

export function PatchedNotNestedPublicTeamCategoryFromJSON(json: any): PatchedNotNestedPublicTeamCategory {
    return PatchedNotNestedPublicTeamCategoryFromJSONTyped(json, false);
}

export function PatchedNotNestedPublicTeamCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedPublicTeamCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
    };
}

export function PatchedNotNestedPublicTeamCategoryToJSON(value?: Omit<PatchedNotNestedPublicTeamCategory, 'id'|'meeting'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
    };
}

