/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface ParticipantsDailyTaskTeam
 */
export interface ParticipantsDailyTaskTeam {
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipantsDailyTaskTeam
     */
    participants: Array<string>;
}

/**
 * Check if a given object implements the ParticipantsDailyTaskTeam interface.
 */
export function instanceOfParticipantsDailyTaskTeam(value: object): boolean {
    if (!('participants' in value)) return false;
    return true;
}

export function ParticipantsDailyTaskTeamFromJSON(json: any): ParticipantsDailyTaskTeam {
    return ParticipantsDailyTaskTeamFromJSONTyped(json, false);
}

export function ParticipantsDailyTaskTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantsDailyTaskTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'participants': json['participants'],
    };
}

export function ParticipantsDailyTaskTeamToJSON(value?: ParticipantsDailyTaskTeam | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'participants': value['participants'],
    };
}

