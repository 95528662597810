/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `observation` - Observation
 * * `entretien` - Entretien
 * * `avis` - Avis
 * * `commentaire_envoi` - Commentaire d'envoi
 * * `prep_entretien` - Préparation d'entretien
 * @export
 */
export const TypeEnum = {
    Observation: 'observation',
    Entretien: 'entretien',
    Avis: 'avis',
    CommentaireEnvoi: 'commentaire_envoi',
    PrepEntretien: 'prep_entretien'
} as const;
export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];


export function instanceOfTypeEnum(value: any): boolean {
    return Object.values(TypeEnum).includes(value);
}

export function TypeEnumFromJSON(json: any): TypeEnum {
    return TypeEnumFromJSONTyped(json, false);
}

export function TypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeEnum {
    return json as TypeEnum;
}

export function TypeEnumToJSON(value?: TypeEnum | null): any {
    return value as any;
}

