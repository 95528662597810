import { MeetingClient } from "../clients/MeetingClient";
import { getConfiguration } from "../configuration";

let instance: MeetingClient | undefined;

export const meetingService = {
  getInstance: () => {
    if (instance == undefined) {
      instance = meetingService.createInstance();
    }
    return instance;
  },
  createInstance: () => {
    const accessToken = localStorage.getItem("access_token") ?? undefined;
    const conf = getConfiguration(accessToken);
    instance = new MeetingClient(conf);
    return instance;
  },
};
