/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    access: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    refresh: string;
}

/**
 * Check if a given object implements the Token interface.
 */
export function instanceOfToken(value: object): boolean {
    if (!('access' in value)) return false;
    if (!('refresh' in value)) return false;
    return true;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if (json == null) {
        return json;
    }
    return {
        
        'access': json['access'],
        'refresh': json['refresh'],
    };
}

export function TokenToJSON(value?: Token | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'access': value['access'],
        'refresh': value['refresh'],
    };
}

