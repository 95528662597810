/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `open` - Ouvert
 * * `close` - Fermé
 * * `committed` - Complété
 * * `cancelled` - Annulé
 * @export
 */
export const StatusEnum = {
    Open: 'open',
    Close: 'close',
    Committed: 'committed',
    Cancelled: 'cancelled'
} as const;
export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


export function instanceOfStatusEnum(value: any): boolean {
    return Object.values(StatusEnum).includes(value);
}

export function StatusEnumFromJSON(json: any): StatusEnum {
    return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusEnum {
    return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
    return value as any;
}

