/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedPlace
 */
export interface NotNestedPlace {
    /**
     * 
     * @type {string}
     * @memberof NotNestedPlace
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPlace
     */
    readonly label: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPlace
     */
    readonly address: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPlace
     */
    readonly zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof NotNestedPlace
     */
    readonly city: string;
}

/**
 * Check if a given object implements the NotNestedPlace interface.
 */
export function instanceOfNotNestedPlace(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('address' in value)) return false;
    if (!('zipcode' in value)) return false;
    if (!('city' in value)) return false;
    return true;
}

export function NotNestedPlaceFromJSON(json: any): NotNestedPlace {
    return NotNestedPlaceFromJSONTyped(json, false);
}

export function NotNestedPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedPlace {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'address': json['address'],
        'zipcode': json['zipcode'],
        'city': json['city'],
    };
}

export function NotNestedPlaceToJSON(value?: Omit<NotNestedPlace, 'id'|'label'|'address'|'zipcode'|'city'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

