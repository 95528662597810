import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.digiform\.sgdf\.fr\/api/,
      /^https:\/\/api-digiform\.cleverapps\.io\/api/, // cleverapps.io/api
    ],

    // replaysSessionSampleRate: 0.1, // Change in prod
    // replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  });
});
