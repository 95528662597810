/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingModule } from './MeetingModule';
import {
    MeetingModuleFromJSON,
    MeetingModuleFromJSONTyped,
    MeetingModuleToJSON,
} from './MeetingModule';
import type { MeetingType } from './MeetingType';
import {
    MeetingTypeFromJSON,
    MeetingTypeFromJSONTyped,
    MeetingTypeToJSON,
} from './MeetingType';
import type { NotNestedPlace } from './NotNestedPlace';
import {
    NotNestedPlaceFromJSON,
    NotNestedPlaceFromJSONTyped,
    NotNestedPlaceToJSON,
} from './NotNestedPlace';
import type { StructureType } from './StructureType';
import {
    StructureTypeFromJSON,
    StructureTypeFromJSONTyped,
    StructureTypeToJSON,
} from './StructureType';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingPublic
 */
export interface MeetingPublic {
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly label: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingPublic
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingPublic
     */
    readonly end_date: Date;
    /**
     * 
     * @type {MeetingType}
     * @memberof MeetingPublic
     */
    readonly meeting_type: MeetingType;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly meeting_type_id: string | null;
    /**
     * 
     * @type {NotNestedPlace}
     * @memberof MeetingPublic
     */
    readonly place: NotNestedPlace;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly place_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingPublic
     */
    readonly start_date: Date;
    /**
     * 
     * @type {StructureType}
     * @memberof MeetingPublic
     */
    readonly structure_organisatrice: StructureType;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly structure_organisatrice_id: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MeetingPublic
     */
    readonly updated_at: Date;
    /**
     * 
     * @type {Array<MeetingModule>}
     * @memberof MeetingPublic
     */
    modules: Array<MeetingModule>;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly daytime_min: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingPublic
     */
    readonly daytime_max: string;
}

/**
 * Check if a given object implements the MeetingPublic interface.
 */
export function instanceOfMeetingPublic(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('created_at' in value)) return false;
    if (!('end_date' in value)) return false;
    if (!('meeting_type' in value)) return false;
    if (!('meeting_type_id' in value)) return false;
    if (!('place' in value)) return false;
    if (!('place_id' in value)) return false;
    if (!('start_date' in value)) return false;
    if (!('structure_organisatrice' in value)) return false;
    if (!('structure_organisatrice_id' in value)) return false;
    if (!('updated_at' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('daytime_min' in value)) return false;
    if (!('daytime_max' in value)) return false;
    return true;
}

export function MeetingPublicFromJSON(json: any): MeetingPublic {
    return MeetingPublicFromJSONTyped(json, false);
}

export function MeetingPublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingPublic {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'created_at': (new Date(json['created_at'])),
        'end_date': (new Date(json['end_date'])),
        'meeting_type': MeetingTypeFromJSON(json['meeting_type']),
        'meeting_type_id': json['meeting_type_id'],
        'place': NotNestedPlaceFromJSON(json['place']),
        'place_id': json['place_id'],
        'start_date': (new Date(json['start_date'])),
        'structure_organisatrice': StructureTypeFromJSON(json['structure_organisatrice']),
        'structure_organisatrice_id': json['structure_organisatrice_id'],
        'updated_at': (new Date(json['updated_at'])),
        'modules': ((json['modules'] as Array<any>).map(MeetingModuleFromJSON)),
        'daytime_min': json['daytime_min'],
        'daytime_max': json['daytime_max'],
    };
}

export function MeetingPublicToJSON(value?: Omit<MeetingPublic, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modules': ((value['modules'] as Array<any>).map(MeetingModuleToJSON)),
    };
}

