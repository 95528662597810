/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotNestedPlace,
  PaginatedNotNestedPlaceList,
} from '../models/index';
import {
    NotNestedPlaceFromJSON,
    NotNestedPlaceToJSON,
    PaginatedNotNestedPlaceListFromJSON,
    PaginatedNotNestedPlaceListToJSON,
} from '../models/index';

export interface PlacesListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface PlacesRetrieveRequest {
    uuid: string;
}

/**
 * 
 */
export class PlacesApi extends runtime.BaseAPI {

    /**
     * Un \"Place\" correspond à un lieu d\'action de formation ou d\'événement
     */
    async placesListRaw(requestParameters: PlacesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotNestedPlaceList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/places/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotNestedPlaceListFromJSON(jsonValue));
    }

    /**
     * Un \"Place\" correspond à un lieu d\'action de formation ou d\'événement
     */
    async placesList(requestParameters: PlacesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotNestedPlaceList> {
        const response = await this.placesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Place\" correspond à un lieu d\'action de formation ou d\'événement
     */
    async placesRetrieveRaw(requestParameters: PlacesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPlace>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling placesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/places/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPlaceFromJSON(jsonValue));
    }

    /**
     * Un \"Place\" correspond à un lieu d\'action de formation ou d\'événement
     */
    async placesRetrieve(requestParameters: PlacesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPlace> {
        const response = await this.placesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
