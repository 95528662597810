/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_sync` - Avis non synchronisé
 * * `candidate_error` - Le candidat associé à cet avis est en erreur
 * * `incoherent_avis` - L'avis digiform est différent de celui sur TAGADA
 * * `synchronized` - L'avis a été enregistré sur TAGADA
 * * `sync_outdated` - L'avis a été modifié depuis ledernier enregistrement sur TAGADA
 * @export
 */
export const TagadaAvisStatusEnum = {
    NotSync: 'not_sync',
    CandidateError: 'candidate_error',
    IncoherentAvis: 'incoherent_avis',
    Synchronized: 'synchronized',
    SyncOutdated: 'sync_outdated'
} as const;
export type TagadaAvisStatusEnum = typeof TagadaAvisStatusEnum[keyof typeof TagadaAvisStatusEnum];


export function instanceOfTagadaAvisStatusEnum(value: any): boolean {
    return Object.values(TagadaAvisStatusEnum).includes(value);
}

export function TagadaAvisStatusEnumFromJSON(json: any): TagadaAvisStatusEnum {
    return TagadaAvisStatusEnumFromJSONTyped(json, false);
}

export function TagadaAvisStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagadaAvisStatusEnum {
    return json as TagadaAvisStatusEnum;
}

export function TagadaAvisStatusEnumToJSON(value?: TagadaAvisStatusEnum | null): any {
    return value as any;
}

