/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `1` - man
 * * `2` - woman
 * * `3` - other
 * @export
 */
export const GenderEnum = {
    _1: '1',
    _2: '2',
    _3: '3'
} as const;
export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];


export function instanceOfGenderEnum(value: any): boolean {
    return Object.values(GenderEnum).includes(value);
}

export function GenderEnumFromJSON(json: any): GenderEnum {
    return GenderEnumFromJSONTyped(json, false);
}

export function GenderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderEnum {
    return json as GenderEnum;
}

export function GenderEnumToJSON(value?: GenderEnum | null): any {
    return value as any;
}

