/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedNotNestedPublicRoom
 */
export interface PatchedNotNestedPublicRoom {
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicRoom
     */
    readonly id?: string;
    /**
     * Nombre de personnes autorisées dans la salle
     * @type {number}
     * @memberof PatchedNotNestedPublicRoom
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicRoom
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicRoom
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotNestedPublicRoom
     */
    readonly meeting?: string;
}

/**
 * Check if a given object implements the PatchedNotNestedPublicRoom interface.
 */
export function instanceOfPatchedNotNestedPublicRoom(value: object): boolean {
    return true;
}

export function PatchedNotNestedPublicRoomFromJSON(json: any): PatchedNotNestedPublicRoom {
    return PatchedNotNestedPublicRoomFromJSONTyped(json, false);
}

export function PatchedNotNestedPublicRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotNestedPublicRoom {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'] == null ? undefined : json['label'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
    };
}

export function PatchedNotNestedPublicRoomToJSON(value?: Omit<PatchedNotNestedPublicRoom, 'id'|'meeting'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'capacity': value['capacity'],
        'description': value['description'],
        'label': value['label'],
    };
}

