/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NestedMeetingComment
 */
export interface NestedMeetingComment {
    /**
     * 
     * @type {string}
     * @memberof NestedMeetingComment
     */
    text?: string;
}

/**
 * Check if a given object implements the NestedMeetingComment interface.
 */
export function instanceOfNestedMeetingComment(value: object): boolean {
    return true;
}

export function NestedMeetingCommentFromJSON(json: any): NestedMeetingComment {
    return NestedMeetingCommentFromJSONTyped(json, false);
}

export function NestedMeetingCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NestedMeetingComment {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
    };
}

export function NestedMeetingCommentToJSON(value?: NestedMeetingComment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
    };
}

