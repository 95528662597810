/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NoCommentMeetingParticipant } from './NoCommentMeetingParticipant';
import {
    NoCommentMeetingParticipantFromJSON,
    NoCommentMeetingParticipantFromJSONTyped,
    NoCommentMeetingParticipantToJSON,
} from './NoCommentMeetingParticipant';

/**
 * 
 * @export
 * @interface PaginatedNoCommentMeetingParticipantList
 */
export interface PaginatedNoCommentMeetingParticipantList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNoCommentMeetingParticipantList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoCommentMeetingParticipantList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoCommentMeetingParticipantList
     */
    previous?: string;
    /**
     * 
     * @type {Array<NoCommentMeetingParticipant>}
     * @memberof PaginatedNoCommentMeetingParticipantList
     */
    results: Array<NoCommentMeetingParticipant>;
}

/**
 * Check if a given object implements the PaginatedNoCommentMeetingParticipantList interface.
 */
export function instanceOfPaginatedNoCommentMeetingParticipantList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedNoCommentMeetingParticipantListFromJSON(json: any): PaginatedNoCommentMeetingParticipantList {
    return PaginatedNoCommentMeetingParticipantListFromJSONTyped(json, false);
}

export function PaginatedNoCommentMeetingParticipantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedNoCommentMeetingParticipantList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(NoCommentMeetingParticipantFromJSON)),
    };
}

export function PaginatedNoCommentMeetingParticipantListToJSON(value?: PaginatedNoCommentMeetingParticipantList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(NoCommentMeetingParticipantToJSON)),
    };
}

