/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OpinionParticipant } from './OpinionParticipant';
import {
    OpinionParticipantFromJSON,
    OpinionParticipantFromJSONTyped,
    OpinionParticipantToJSON,
} from './OpinionParticipant';

/**
 * 
 * @export
 * @interface PaginatedOpinionParticipantList
 */
export interface PaginatedOpinionParticipantList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedOpinionParticipantList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOpinionParticipantList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOpinionParticipantList
     */
    previous?: string;
    /**
     * 
     * @type {Array<OpinionParticipant>}
     * @memberof PaginatedOpinionParticipantList
     */
    results: Array<OpinionParticipant>;
}

/**
 * Check if a given object implements the PaginatedOpinionParticipantList interface.
 */
export function instanceOfPaginatedOpinionParticipantList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedOpinionParticipantListFromJSON(json: any): PaginatedOpinionParticipantList {
    return PaginatedOpinionParticipantListFromJSONTyped(json, false);
}

export function PaginatedOpinionParticipantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedOpinionParticipantList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(OpinionParticipantFromJSON)),
    };
}

export function PaginatedOpinionParticipantListToJSON(value?: PaginatedOpinionParticipantList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(OpinionParticipantToJSON)),
    };
}

