/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResourceTypeEnum } from './ResourceTypeEnum';
import {
    ResourceTypeEnumFromJSON,
    ResourceTypeEnumFromJSONTyped,
    ResourceTypeEnumToJSON,
} from './ResourceTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface CreateNotNestedResource
 */
export interface CreateNotNestedResource {
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    description?: string;
    /**
     * Type de diplôme
     * 
     * * `content` - Contenu
     * * `link` - Lien
     * * `media` - Média
     * * `category` - Catégorie
     * @type {ResourceTypeEnum}
     * @memberof CreateNotNestedResource
     */
    resource_type: ResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    content?: string;
    /**
     * La ressource a-t-elle été créée par l'équipe de formation
     * @type {boolean}
     * @memberof CreateNotNestedResource
     */
    trusted?: boolean;
    /**
     * Date de publication de la resource
     * @type {Date}
     * @memberof CreateNotNestedResource
     */
    publication_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateNotNestedResource
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNotNestedResource
     */
    parent?: string;
}

/**
 * Check if a given object implements the CreateNotNestedResource interface.
 */
export function instanceOfCreateNotNestedResource(value: object): boolean {
    if (!('label' in value)) return false;
    if (!('resource_type' in value)) return false;
    if (!('order' in value)) return false;
    return true;
}

export function CreateNotNestedResourceFromJSON(json: any): CreateNotNestedResource {
    return CreateNotNestedResourceFromJSONTyped(json, false);
}

export function CreateNotNestedResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNotNestedResource {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'resource_type': ResourceTypeEnumFromJSON(json['resource_type']),
        'url': json['url'] == null ? undefined : json['url'],
        'content': json['content'] == null ? undefined : json['content'],
        'trusted': json['trusted'] == null ? undefined : json['trusted'],
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'order': json['order'],
        'parent': json['parent'] == null ? undefined : json['parent'],
    };
}

export function CreateNotNestedResourceToJSON(value?: CreateNotNestedResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'label': value['label'],
        'description': value['description'],
        'resource_type': ResourceTypeEnumToJSON(value['resource_type']),
        'url': value['url'],
        'content': value['content'],
        'trusted': value['trusted'],
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'order': value['order'],
        'parent': value['parent'],
    };
}

