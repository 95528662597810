/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedPublicMeetingParticipantWithAvatar } from './NotNestedPublicMeetingParticipantWithAvatar';
import {
    NotNestedPublicMeetingParticipantWithAvatarFromJSON,
    NotNestedPublicMeetingParticipantWithAvatarFromJSONTyped,
    NotNestedPublicMeetingParticipantWithAvatarToJSON,
} from './NotNestedPublicMeetingParticipantWithAvatar';
import type { NotNestedPublicTeamCategory } from './NotNestedPublicTeamCategory';
import {
    NotNestedPublicTeamCategoryFromJSON,
    NotNestedPublicTeamCategoryFromJSONTyped,
    NotNestedPublicTeamCategoryToJSON,
} from './NotNestedPublicTeamCategory';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedTeam
 */
export interface PatchedTeam {
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    color?: string;
    /**
     * The meeting associated to the team
     * @type {string}
     * @memberof PatchedTeam
     */
    readonly meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    category_id?: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipantWithAvatar>}
     * @memberof PatchedTeam
     */
    participants?: Array<NotNestedPublicMeetingParticipantWithAvatar>;
    /**
     * 
     * @type {NotNestedPublicTeamCategory}
     * @memberof PatchedTeam
     */
    category?: NotNestedPublicTeamCategory;
}

/**
 * Check if a given object implements the PatchedTeam interface.
 */
export function instanceOfPatchedTeam(value: object): boolean {
    return true;
}

export function PatchedTeamFromJSON(json: any): PatchedTeam {
    return PatchedTeamFromJSONTyped(json, false);
}

export function PatchedTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'color': json['color'] == null ? undefined : json['color'],
        'meeting': json['meeting'] == null ? undefined : json['meeting'],
        'category_id': json['category_id'] == null ? undefined : json['category_id'],
        'participants': json['participants'] == null ? undefined : ((json['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarFromJSON)),
        'category': json['category'] == null ? undefined : NotNestedPublicTeamCategoryFromJSON(json['category']),
    };
}

export function PatchedTeamToJSON(value?: Omit<PatchedTeam, 'id'|'meeting'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'color': value['color'],
        'category_id': value['category_id'],
        'participants': value['participants'] == null ? undefined : ((value['participants'] as Array<any>).map(NotNestedPublicMeetingParticipantWithAvatarToJSON)),
        'category': NotNestedPublicTeamCategoryToJSON(value['category']),
    };
}

