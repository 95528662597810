import { FetchError, ResponseError } from "@sgdf/client";
import { NotificationType, useNotifyStore } from "~/composables/notify-store";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const notifyStore = useNotifyStore();
  nuxtApp.vueApp.config.errorHandler = async (..._args) => {
    if (_args[0] instanceof ResponseError) {
      const resp = await _args[0].response.json();
      notifyStore.notifyDetailed(
        resp.code ?? "Error",
        resp.detail ?? JSON.stringify(resp),
        NotificationType.Danger
      );
    } else if (_args[0] instanceof FetchError) {
      const cause = await _args[0].cause;
      notifyStore.notifyDetailed(
        cause.name ?? "FetchError",
        cause.message ?? JSON.stringify(_args[0]),
        NotificationType.Danger
      );
    } else {
      notifyStore.notifyError(_args[0], NotificationType.Danger);
    }

    Sentry.captureException(_args[0]);
  };
});
