/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PublicNotNestedResource } from './PublicNotNestedResource';
import {
    PublicNotNestedResourceFromJSON,
    PublicNotNestedResourceFromJSONTyped,
    PublicNotNestedResourceToJSON,
} from './PublicNotNestedResource';
import type { ResourceNode } from './ResourceNode';
import {
    ResourceNodeFromJSON,
    ResourceNodeFromJSONTyped,
    ResourceNodeToJSON,
} from './ResourceNode';

/**
 * 
 * @export
 * @interface PublicResourceTreeList
 */
export interface PublicResourceTreeList {
    /**
     * 
     * @type {Array<ResourceNode>}
     * @memberof PublicResourceTreeList
     */
    readonly tree: Array<ResourceNode>;
    /**
     * 
     * @type {Array<PublicNotNestedResource>}
     * @memberof PublicResourceTreeList
     */
    readonly list: Array<PublicNotNestedResource>;
}

/**
 * Check if a given object implements the PublicResourceTreeList interface.
 */
export function instanceOfPublicResourceTreeList(value: object): boolean {
    if (!('tree' in value)) return false;
    if (!('list' in value)) return false;
    return true;
}

export function PublicResourceTreeListFromJSON(json: any): PublicResourceTreeList {
    return PublicResourceTreeListFromJSONTyped(json, false);
}

export function PublicResourceTreeListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicResourceTreeList {
    if (json == null) {
        return json;
    }
    return {
        
        'tree': ((json['tree'] as Array<any>).map(ResourceNodeFromJSON)),
        'list': ((json['list'] as Array<any>).map(PublicNotNestedResourceFromJSON)),
    };
}

export function PublicResourceTreeListToJSON(value?: Omit<PublicResourceTreeList, 'tree'|'list'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

