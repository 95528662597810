/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DailyRoutineApply,
  EventWithActivity,
  NotNestedEvent,
  NotNestedEventWithNestedActivity,
  NotNestedPublicActivityCategory,
  PaginatedEventList,
  PaginatedNotNestedPublicActivityCategoryList,
  PaginatedPublicActivityList,
  PaginatedPublicEventList,
  PatchedNotNestedEvent,
  PatchedNotNestedEventWithNestedActivity,
  PatchedNotNestedPublicActivityCategory,
  PatchedPublicActivity,
  PublicActivity,
} from '../models/index';
import {
    DailyRoutineApplyFromJSON,
    DailyRoutineApplyToJSON,
    EventWithActivityFromJSON,
    EventWithActivityToJSON,
    NotNestedEventFromJSON,
    NotNestedEventToJSON,
    NotNestedEventWithNestedActivityFromJSON,
    NotNestedEventWithNestedActivityToJSON,
    NotNestedPublicActivityCategoryFromJSON,
    NotNestedPublicActivityCategoryToJSON,
    PaginatedEventListFromJSON,
    PaginatedEventListToJSON,
    PaginatedNotNestedPublicActivityCategoryListFromJSON,
    PaginatedNotNestedPublicActivityCategoryListToJSON,
    PaginatedPublicActivityListFromJSON,
    PaginatedPublicActivityListToJSON,
    PaginatedPublicEventListFromJSON,
    PaginatedPublicEventListToJSON,
    PatchedNotNestedEventFromJSON,
    PatchedNotNestedEventToJSON,
    PatchedNotNestedEventWithNestedActivityFromJSON,
    PatchedNotNestedEventWithNestedActivityToJSON,
    PatchedNotNestedPublicActivityCategoryFromJSON,
    PatchedNotNestedPublicActivityCategoryToJSON,
    PatchedPublicActivityFromJSON,
    PatchedPublicActivityToJSON,
    PublicActivityFromJSON,
    PublicActivityToJSON,
} from '../models/index';

export interface MeetingsActivitiesCreateRequest {
    meetingUuid: string;
    publicActivity: Omit<PublicActivity, 'id'|'meeting_id'|'category'|'tracks'>;
}

export interface MeetingsActivitiesDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsActivitiesListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsActivitiesPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedPublicActivity?: Omit<PatchedPublicActivity, 'id'|'meeting_id'|'category'|'tracks'>;
}

export interface MeetingsActivitiesRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsActivitiesUpdateRequest {
    meetingUuid: string;
    uuid: string;
    publicActivity: Omit<PublicActivity, 'id'|'meeting_id'|'category'|'tracks'>;
}

export interface MeetingsActivityCategoriesCreateRequest {
    meetingUuid: string;
    notNestedPublicActivityCategory: Omit<NotNestedPublicActivityCategory, 'id'|'meeting'>;
}

export interface MeetingsActivityCategoriesDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsActivityCategoriesListRequest {
    meetingUuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsActivityCategoriesPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedPublicActivityCategory?: Omit<PatchedNotNestedPublicActivityCategory, 'id'|'meeting'>;
}

export interface MeetingsActivityCategoriesRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsActivityCategoriesUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedPublicActivityCategory: Omit<NotNestedPublicActivityCategory, 'id'|'meeting'>;
}

export interface MeetingsEventsApplyDailyRoutineCreateRequest {
    meetingUuid: string;
    dailyRoutineApply: DailyRoutineApply;
    eventType?: MeetingsEventsApplyDailyRoutineCreateEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsCopyToDaysCreateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedEvent: Omit<NotNestedEvent, 'id'|'meeting_id'>;
}

export interface MeetingsEventsCreateRequest {
    meetingUuid: string;
    notNestedEvent: Omit<NotNestedEvent, 'id'|'meeting_id'>;
}

export interface MeetingsEventsDailyRoutineListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsDailyRoutineListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsEventsInChargeListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsInChargeListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsListEventTypeEnum;
    filterByIsInCharge?: boolean;
    filterByIsSupervisor?: boolean;
    filterOnlyFuture?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    participantUuid?: string;
    search?: string;
    teamUuid?: string;
}

export interface MeetingsEventsMyDailyEventsListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsMyDailyEventsListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsOwnPlanningListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsOwnPlanningListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedEvent?: Omit<PatchedNotNestedEvent, 'id'|'meeting_id'>;
}

export interface MeetingsEventsPlanningRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsEventsPublicListListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsPublicListListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsEventsSupervisorListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsSupervisorListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedEvent: Omit<NotNestedEvent, 'id'|'meeting_id'>;
    moveNeighbours?: string;
}

export interface MeetingsEventsWithActivityApplyDailyRoutineCreateRequest {
    meetingUuid: string;
    dailyRoutineApply: DailyRoutineApply;
    eventType?: MeetingsEventsWithActivityApplyDailyRoutineCreateEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityCopyToDaysCreateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedEventWithNestedActivity: Omit<NotNestedEventWithNestedActivity, 'id'|'meeting_id'>;
}

export interface MeetingsEventsWithActivityCreateRequest {
    meetingUuid: string;
    notNestedEventWithNestedActivity: Omit<NotNestedEventWithNestedActivity, 'id'|'meeting_id'>;
}

export interface MeetingsEventsWithActivityDailyRoutineListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityDailyRoutineListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsEventsWithActivityInChargeListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityInChargeListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityListEventTypeEnum;
    filterByIsInCharge?: boolean;
    filterByIsSupervisor?: boolean;
    filterOnlyFuture?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    participantUuid?: string;
    search?: string;
    teamUuid?: string;
}

export interface MeetingsEventsWithActivityMyDailyEventsListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityMyDailyEventsListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityOwnPlanningListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityOwnPlanningListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedNotNestedEventWithNestedActivity?: Omit<PatchedNotNestedEventWithNestedActivity, 'id'|'meeting_id'>;
}

export interface MeetingsEventsWithActivityPlanningRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsEventsWithActivityPublicListListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivityPublicListListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsEventsWithActivitySupervisorListRequest {
    meetingUuid: string;
    eventType?: MeetingsEventsWithActivitySupervisorListEventTypeEnum;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsEventsWithActivityUpdateRequest {
    meetingUuid: string;
    uuid: string;
    notNestedEventWithNestedActivity: Omit<NotNestedEventWithNestedActivity, 'id'|'meeting_id'>;
    moveNeighbours?: string;
}

/**
 * 
 */
export class EventsApi extends runtime.BaseAPI {

    /**
     * Créer une activité d\'évenement
     */
    async meetingsActivitiesCreateRaw(requestParameters: MeetingsActivitiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesCreate().'
            );
        }

        if (requestParameters['publicActivity'] == null) {
            throw new runtime.RequiredError(
                'publicActivity',
                'Required parameter "publicActivity" was null or undefined when calling meetingsActivitiesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublicActivityToJSON(requestParameters['publicActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicActivityFromJSON(jsonValue));
    }

    /**
     * Créer une activité d\'évenement
     */
    async meetingsActivitiesCreate(requestParameters: MeetingsActivitiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicActivity> {
        const response = await this.meetingsActivitiesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Détruire une activité
     */
    async meetingsActivitiesDestroyRaw(requestParameters: MeetingsActivitiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivitiesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Détruire une activité
     */
    async meetingsActivitiesDestroy(requestParameters: MeetingsActivitiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsActivitiesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Lister les activités d\'évenement
     */
    async meetingsActivitiesListRaw(requestParameters: MeetingsActivitiesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicActivityList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicActivityListFromJSON(jsonValue));
    }

    /**
     * Lister les activités d\'évenement
     */
    async meetingsActivitiesList(requestParameters: MeetingsActivitiesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicActivityList> {
        const response = await this.meetingsActivitiesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour partiellement une activité
     */
    async meetingsActivitiesPartialUpdateRaw(requestParameters: MeetingsActivitiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivitiesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedPublicActivityToJSON(requestParameters['patchedPublicActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicActivityFromJSON(jsonValue));
    }

    /**
     * Mettre à jour partiellement une activité
     */
    async meetingsActivitiesPartialUpdate(requestParameters: MeetingsActivitiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicActivity> {
        const response = await this.meetingsActivitiesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupérer les détails d\'une activité
     */
    async meetingsActivitiesRetrieveRaw(requestParameters: MeetingsActivitiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivitiesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicActivityFromJSON(jsonValue));
    }

    /**
     * Récupérer les détails d\'une activité
     */
    async meetingsActivitiesRetrieve(requestParameters: MeetingsActivitiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicActivity> {
        const response = await this.meetingsActivitiesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour une activité
     */
    async meetingsActivitiesUpdateRaw(requestParameters: MeetingsActivitiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivitiesUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivitiesUpdate().'
            );
        }

        if (requestParameters['publicActivity'] == null) {
            throw new runtime.RequiredError(
                'publicActivity',
                'Required parameter "publicActivity" was null or undefined when calling meetingsActivitiesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activities/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PublicActivityToJSON(requestParameters['publicActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicActivityFromJSON(jsonValue));
    }

    /**
     * Mettre à jour une activité
     */
    async meetingsActivitiesUpdate(requestParameters: MeetingsActivitiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicActivity> {
        const response = await this.meetingsActivitiesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Créer un catégorie d\'évenement
     */
    async meetingsActivityCategoriesCreateRaw(requestParameters: MeetingsActivityCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicActivityCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesCreate().'
            );
        }

        if (requestParameters['notNestedPublicActivityCategory'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicActivityCategory',
                'Required parameter "notNestedPublicActivityCategory" was null or undefined when calling meetingsActivityCategoriesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicActivityCategoryToJSON(requestParameters['notNestedPublicActivityCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicActivityCategoryFromJSON(jsonValue));
    }

    /**
     * Créer un catégorie d\'évenement
     */
    async meetingsActivityCategoriesCreate(requestParameters: MeetingsActivityCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicActivityCategory> {
        const response = await this.meetingsActivityCategoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Détruire une catégorie d\'activité
     */
    async meetingsActivityCategoriesDestroyRaw(requestParameters: MeetingsActivityCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivityCategoriesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Détruire une catégorie d\'activité
     */
    async meetingsActivityCategoriesDestroy(requestParameters: MeetingsActivityCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsActivityCategoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List the customized categories and also the generic categories
     */
    async meetingsActivityCategoriesListRaw(requestParameters: MeetingsActivityCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotNestedPublicActivityCategoryList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotNestedPublicActivityCategoryListFromJSON(jsonValue));
    }

    /**
     * List the customized categories and also the generic categories
     */
    async meetingsActivityCategoriesList(requestParameters: MeetingsActivityCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotNestedPublicActivityCategoryList> {
        const response = await this.meetingsActivityCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour partiellement une catégorie d\'évenement
     */
    async meetingsActivityCategoriesPartialUpdateRaw(requestParameters: MeetingsActivityCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicActivityCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivityCategoriesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedPublicActivityCategoryToJSON(requestParameters['patchedNotNestedPublicActivityCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicActivityCategoryFromJSON(jsonValue));
    }

    /**
     * Mettre à jour partiellement une catégorie d\'évenement
     */
    async meetingsActivityCategoriesPartialUpdate(requestParameters: MeetingsActivityCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicActivityCategory> {
        const response = await this.meetingsActivityCategoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the customized categories and also the generic categories
     */
    async meetingsActivityCategoriesRetrieveRaw(requestParameters: MeetingsActivityCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicActivityCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivityCategoriesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicActivityCategoryFromJSON(jsonValue));
    }

    /**
     * Retrieve the customized categories and also the generic categories
     */
    async meetingsActivityCategoriesRetrieve(requestParameters: MeetingsActivityCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicActivityCategory> {
        const response = await this.meetingsActivityCategoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour une catégorie d\'evenement
     */
    async meetingsActivityCategoriesUpdateRaw(requestParameters: MeetingsActivityCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedPublicActivityCategory>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsActivityCategoriesUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsActivityCategoriesUpdate().'
            );
        }

        if (requestParameters['notNestedPublicActivityCategory'] == null) {
            throw new runtime.RequiredError(
                'notNestedPublicActivityCategory',
                'Required parameter "notNestedPublicActivityCategory" was null or undefined when calling meetingsActivityCategoriesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/activity-categories/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedPublicActivityCategoryToJSON(requestParameters['notNestedPublicActivityCategory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedPublicActivityCategoryFromJSON(jsonValue));
    }

    /**
     * Mettre à jour une catégorie d\'evenement
     */
    async meetingsActivityCategoriesUpdate(requestParameters: MeetingsActivityCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedPublicActivityCategory> {
        const response = await this.meetingsActivityCategoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applique la journée type sur les jours indiqués
     */
    async meetingsEventsApplyDailyRoutineCreateRaw(requestParameters: MeetingsEventsApplyDailyRoutineCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsApplyDailyRoutineCreate().'
            );
        }

        if (requestParameters['dailyRoutineApply'] == null) {
            throw new runtime.RequiredError(
                'dailyRoutineApply',
                'Required parameter "dailyRoutineApply" was null or undefined when calling meetingsEventsApplyDailyRoutineCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/apply_daily_routine/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DailyRoutineApplyToJSON(requestParameters['dailyRoutineApply']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEventListFromJSON(jsonValue));
    }

    /**
     * Applique la journée type sur les jours indiqués
     */
    async meetingsEventsApplyDailyRoutineCreate(requestParameters: MeetingsEventsApplyDailyRoutineCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEventList> {
        const response = await this.meetingsEventsApplyDailyRoutineCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applique l\'evenement sur les jours indiqués
     */
    async meetingsEventsCopyToDaysCreateRaw(requestParameters: MeetingsEventsCopyToDaysCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsCopyToDaysCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsCopyToDaysCreate().'
            );
        }

        if (requestParameters['notNestedEvent'] == null) {
            throw new runtime.RequiredError(
                'notNestedEvent',
                'Required parameter "notNestedEvent" was null or undefined when calling meetingsEventsCopyToDaysCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/{uuid}/copy-to-days/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventToJSON(requestParameters['notNestedEvent']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Applique l\'evenement sur les jours indiqués
     */
    async meetingsEventsCopyToDaysCreate(requestParameters: MeetingsEventsCopyToDaysCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsCopyToDaysCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Créer un evenement
     */
    async meetingsEventsCreateRaw(requestParameters: MeetingsEventsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsCreate().'
            );
        }

        if (requestParameters['notNestedEvent'] == null) {
            throw new runtime.RequiredError(
                'notNestedEvent',
                'Required parameter "notNestedEvent" was null or undefined when calling meetingsEventsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventToJSON(requestParameters['notNestedEvent']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Créer un evenement
     */
    async meetingsEventsCreate(requestParameters: MeetingsEventsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements de la journée type
     */
    async meetingsEventsDailyRoutineListRaw(requestParameters: MeetingsEventsDailyRoutineListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsDailyRoutineList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/daily_routine/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements de la journée type
     */
    async meetingsEventsDailyRoutineList(requestParameters: MeetingsEventsDailyRoutineListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsDailyRoutineListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Détruire un evenement
     */
    async meetingsEventsDestroyRaw(requestParameters: MeetingsEventsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Détruire un evenement
     */
    async meetingsEventsDestroy(requestParameters: MeetingsEventsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsEventsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Liste événements futurs dont une équipe du participant est responsable
     */
    async meetingsEventsInChargeListRaw(requestParameters: MeetingsEventsInChargeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsInChargeList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/in_charge/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements futurs dont une équipe du participant est responsable
     */
    async meetingsEventsInChargeList(requestParameters: MeetingsEventsInChargeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsInChargeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lister tout les évenements
     */
    async meetingsEventsListRaw(requestParameters: MeetingsEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['filterByIsInCharge'] != null) {
            queryParameters['filter_by_is_in_charge'] = requestParameters['filterByIsInCharge'];
        }

        if (requestParameters['filterByIsSupervisor'] != null) {
            queryParameters['filter_by_is_supervisor'] = requestParameters['filterByIsSupervisor'];
        }

        if (requestParameters['filterOnlyFuture'] != null) {
            queryParameters['filter_only_future'] = requestParameters['filterOnlyFuture'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['participantUuid'] != null) {
            queryParameters['participant_uuid'] = requestParameters['participantUuid'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['teamUuid'] != null) {
            queryParameters['team_uuid'] = requestParameters['teamUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEventListFromJSON(jsonValue));
    }

    /**
     * Lister tout les évenements
     */
    async meetingsEventsList(requestParameters: MeetingsEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEventList> {
        const response = await this.meetingsEventsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste des événements du jour pour le participant
     */
    async meetingsEventsMyDailyEventsListRaw(requestParameters: MeetingsEventsMyDailyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsMyDailyEventsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/my-daily-events/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste des événements du jour pour le participant
     */
    async meetingsEventsMyDailyEventsList(requestParameters: MeetingsEventsMyDailyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsMyDailyEventsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements dont le participant actuel est responsable / en charge
     */
    async meetingsEventsOwnPlanningListRaw(requestParameters: MeetingsEventsOwnPlanningListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsOwnPlanningList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/own_planning/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements dont le participant actuel est responsable / en charge
     */
    async meetingsEventsOwnPlanningList(requestParameters: MeetingsEventsOwnPlanningListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsOwnPlanningListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour un evenement partiellement
     */
    async meetingsEventsPartialUpdateRaw(requestParameters: MeetingsEventsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedEventToJSON(requestParameters['patchedNotNestedEvent']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Mettre à jour un evenement partiellement
     */
    async meetingsEventsPartialUpdate(requestParameters: MeetingsEventsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Event\" correspond à un créneau temporel et un lien pour un atelier d\'une action de formation
     */
    async meetingsEventsPlanningRetrieveRaw(requestParameters: MeetingsEventsPlanningRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsPlanningRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/planning/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Un \"Event\" correspond à un créneau temporel et un lien pour un atelier d\'une action de formation
     */
    async meetingsEventsPlanningRetrieve(requestParameters: MeetingsEventsPlanningRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsPlanningRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste les evenement du planning étant visible pour l\'utilisateur avec uniquement les attributs public
     */
    async meetingsEventsPublicListListRaw(requestParameters: MeetingsEventsPublicListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsPublicListList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/public_list/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste les evenement du planning étant visible pour l\'utilisateur avec uniquement les attributs public
     */
    async meetingsEventsPublicListList(requestParameters: MeetingsEventsPublicListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsPublicListListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupérer les détails d\'un evenement
     */
    async meetingsEventsRetrieveRaw(requestParameters: MeetingsEventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Récupérer les détails d\'un evenement
     */
    async meetingsEventsRetrieve(requestParameters: MeetingsEventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements futurs dont le participant est responsable
     */
    async meetingsEventsSupervisorListRaw(requestParameters: MeetingsEventsSupervisorListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsSupervisorList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/supervisor/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements futurs dont le participant est responsable
     */
    async meetingsEventsSupervisorList(requestParameters: MeetingsEventsSupervisorListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsSupervisorListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour un évenement
     */
    async meetingsEventsUpdateRaw(requestParameters: MeetingsEventsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEvent>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsUpdate().'
            );
        }

        if (requestParameters['notNestedEvent'] == null) {
            throw new runtime.RequiredError(
                'notNestedEvent',
                'Required parameter "notNestedEvent" was null or undefined when calling meetingsEventsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['moveNeighbours'] != null) {
            queryParameters['move_neighbours'] = requestParameters['moveNeighbours'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventToJSON(requestParameters['notNestedEvent']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventFromJSON(jsonValue));
    }

    /**
     * Mettre à jour un évenement
     */
    async meetingsEventsUpdate(requestParameters: MeetingsEventsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEvent> {
        const response = await this.meetingsEventsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applique la journée type sur les jours indiqués
     */
    async meetingsEventsWithActivityApplyDailyRoutineCreateRaw(requestParameters: MeetingsEventsWithActivityApplyDailyRoutineCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityApplyDailyRoutineCreate().'
            );
        }

        if (requestParameters['dailyRoutineApply'] == null) {
            throw new runtime.RequiredError(
                'dailyRoutineApply',
                'Required parameter "dailyRoutineApply" was null or undefined when calling meetingsEventsWithActivityApplyDailyRoutineCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/apply_daily_routine/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DailyRoutineApplyToJSON(requestParameters['dailyRoutineApply']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEventListFromJSON(jsonValue));
    }

    /**
     * Applique la journée type sur les jours indiqués
     */
    async meetingsEventsWithActivityApplyDailyRoutineCreate(requestParameters: MeetingsEventsWithActivityApplyDailyRoutineCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEventList> {
        const response = await this.meetingsEventsWithActivityApplyDailyRoutineCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applique l\'evenement sur les jours indiqués
     */
    async meetingsEventsWithActivityCopyToDaysCreateRaw(requestParameters: MeetingsEventsWithActivityCopyToDaysCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEventWithNestedActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityCopyToDaysCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsWithActivityCopyToDaysCreate().'
            );
        }

        if (requestParameters['notNestedEventWithNestedActivity'] == null) {
            throw new runtime.RequiredError(
                'notNestedEventWithNestedActivity',
                'Required parameter "notNestedEventWithNestedActivity" was null or undefined when calling meetingsEventsWithActivityCopyToDaysCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/{uuid}/copy-to-days/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventWithNestedActivityToJSON(requestParameters['notNestedEventWithNestedActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventWithNestedActivityFromJSON(jsonValue));
    }

    /**
     * Applique l\'evenement sur les jours indiqués
     */
    async meetingsEventsWithActivityCopyToDaysCreate(requestParameters: MeetingsEventsWithActivityCopyToDaysCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEventWithNestedActivity> {
        const response = await this.meetingsEventsWithActivityCopyToDaysCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Créer un evenement
     */
    async meetingsEventsWithActivityCreateRaw(requestParameters: MeetingsEventsWithActivityCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventWithActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityCreate().'
            );
        }

        if (requestParameters['notNestedEventWithNestedActivity'] == null) {
            throw new runtime.RequiredError(
                'notNestedEventWithNestedActivity',
                'Required parameter "notNestedEventWithNestedActivity" was null or undefined when calling meetingsEventsWithActivityCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventWithNestedActivityToJSON(requestParameters['notNestedEventWithNestedActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventWithActivityFromJSON(jsonValue));
    }

    /**
     * Créer un evenement
     */
    async meetingsEventsWithActivityCreate(requestParameters: MeetingsEventsWithActivityCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventWithActivity> {
        const response = await this.meetingsEventsWithActivityCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements de la journée type
     */
    async meetingsEventsWithActivityDailyRoutineListRaw(requestParameters: MeetingsEventsWithActivityDailyRoutineListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityDailyRoutineList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/daily_routine/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements de la journée type
     */
    async meetingsEventsWithActivityDailyRoutineList(requestParameters: MeetingsEventsWithActivityDailyRoutineListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivityDailyRoutineListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Détruire un evenement
     */
    async meetingsEventsWithActivityDestroyRaw(requestParameters: MeetingsEventsWithActivityDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsWithActivityDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Détruire un evenement
     */
    async meetingsEventsWithActivityDestroy(requestParameters: MeetingsEventsWithActivityDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsEventsWithActivityDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Liste événements futurs dont une équipe du participant est responsable
     */
    async meetingsEventsWithActivityInChargeListRaw(requestParameters: MeetingsEventsWithActivityInChargeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityInChargeList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/in_charge/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements futurs dont une équipe du participant est responsable
     */
    async meetingsEventsWithActivityInChargeList(requestParameters: MeetingsEventsWithActivityInChargeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivityInChargeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lister tous les événements
     */
    async meetingsEventsWithActivityListRaw(requestParameters: MeetingsEventsWithActivityListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['filterByIsInCharge'] != null) {
            queryParameters['filter_by_is_in_charge'] = requestParameters['filterByIsInCharge'];
        }

        if (requestParameters['filterByIsSupervisor'] != null) {
            queryParameters['filter_by_is_supervisor'] = requestParameters['filterByIsSupervisor'];
        }

        if (requestParameters['filterOnlyFuture'] != null) {
            queryParameters['filter_only_future'] = requestParameters['filterOnlyFuture'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['participantUuid'] != null) {
            queryParameters['participant_uuid'] = requestParameters['participantUuid'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['teamUuid'] != null) {
            queryParameters['team_uuid'] = requestParameters['teamUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEventListFromJSON(jsonValue));
    }

    /**
     * Lister tous les événements
     */
    async meetingsEventsWithActivityList(requestParameters: MeetingsEventsWithActivityListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEventList> {
        const response = await this.meetingsEventsWithActivityListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste des événements du jour pour le participant
     */
    async meetingsEventsWithActivityMyDailyEventsListRaw(requestParameters: MeetingsEventsWithActivityMyDailyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityMyDailyEventsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/my-daily-events/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste des événements du jour pour le participant
     */
    async meetingsEventsWithActivityMyDailyEventsList(requestParameters: MeetingsEventsWithActivityMyDailyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivityMyDailyEventsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements dont le participant actuel est responsable / en charge
     */
    async meetingsEventsWithActivityOwnPlanningListRaw(requestParameters: MeetingsEventsWithActivityOwnPlanningListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityOwnPlanningList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/own_planning/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements dont le participant actuel est responsable / en charge
     */
    async meetingsEventsWithActivityOwnPlanningList(requestParameters: MeetingsEventsWithActivityOwnPlanningListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivityOwnPlanningListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour un evenement partiellement
     */
    async meetingsEventsWithActivityPartialUpdateRaw(requestParameters: MeetingsEventsWithActivityPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEventWithNestedActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsWithActivityPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedEventWithNestedActivityToJSON(requestParameters['patchedNotNestedEventWithNestedActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventWithNestedActivityFromJSON(jsonValue));
    }

    /**
     * Mettre à jour un evenement partiellement
     */
    async meetingsEventsWithActivityPartialUpdate(requestParameters: MeetingsEventsWithActivityPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEventWithNestedActivity> {
        const response = await this.meetingsEventsWithActivityPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Event\" correspond à un créneau temporel et un lien pour un atelier d\'une action de formation
     */
    async meetingsEventsWithActivityPlanningRetrieveRaw(requestParameters: MeetingsEventsWithActivityPlanningRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEventWithNestedActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityPlanningRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/planning/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventWithNestedActivityFromJSON(jsonValue));
    }

    /**
     * Un \"Event\" correspond à un créneau temporel et un lien pour un atelier d\'une action de formation
     */
    async meetingsEventsWithActivityPlanningRetrieve(requestParameters: MeetingsEventsWithActivityPlanningRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEventWithNestedActivity> {
        const response = await this.meetingsEventsWithActivityPlanningRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste les evenement du planning étant visible pour l\'utilisateur avec uniquement les attributs public
     */
    async meetingsEventsWithActivityPublicListListRaw(requestParameters: MeetingsEventsWithActivityPublicListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityPublicListList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/public_list/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste les evenement du planning étant visible pour l\'utilisateur avec uniquement les attributs public
     */
    async meetingsEventsWithActivityPublicListList(requestParameters: MeetingsEventsWithActivityPublicListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivityPublicListListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupérer les détails d\'un evenement
     */
    async meetingsEventsWithActivityRetrieveRaw(requestParameters: MeetingsEventsWithActivityRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedEventWithNestedActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsWithActivityRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedEventWithNestedActivityFromJSON(jsonValue));
    }

    /**
     * Récupérer les détails d\'un evenement
     */
    async meetingsEventsWithActivityRetrieve(requestParameters: MeetingsEventsWithActivityRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedEventWithNestedActivity> {
        const response = await this.meetingsEventsWithActivityRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste événements futurs dont le participant est responsable
     */
    async meetingsEventsWithActivitySupervisorListRaw(requestParameters: MeetingsEventsWithActivitySupervisorListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublicEventList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivitySupervisorList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['event_type'] = requestParameters['eventType'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/supervisor/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublicEventListFromJSON(jsonValue));
    }

    /**
     * Liste événements futurs dont le participant est responsable
     */
    async meetingsEventsWithActivitySupervisorList(requestParameters: MeetingsEventsWithActivitySupervisorListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublicEventList> {
        const response = await this.meetingsEventsWithActivitySupervisorListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mettre à jour un évenement
     */
    async meetingsEventsWithActivityUpdateRaw(requestParameters: MeetingsEventsWithActivityUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventWithActivity>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsEventsWithActivityUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsEventsWithActivityUpdate().'
            );
        }

        if (requestParameters['notNestedEventWithNestedActivity'] == null) {
            throw new runtime.RequiredError(
                'notNestedEventWithNestedActivity',
                'Required parameter "notNestedEventWithNestedActivity" was null or undefined when calling meetingsEventsWithActivityUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['moveNeighbours'] != null) {
            queryParameters['move_neighbours'] = requestParameters['moveNeighbours'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/events-with-activity/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedEventWithNestedActivityToJSON(requestParameters['notNestedEventWithNestedActivity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventWithActivityFromJSON(jsonValue));
    }

    /**
     * Mettre à jour un évenement
     */
    async meetingsEventsWithActivityUpdate(requestParameters: MeetingsEventsWithActivityUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventWithActivity> {
        const response = await this.meetingsEventsWithActivityUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const MeetingsEventsApplyDailyRoutineCreateEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsApplyDailyRoutineCreateEventTypeEnum = typeof MeetingsEventsApplyDailyRoutineCreateEventTypeEnum[keyof typeof MeetingsEventsApplyDailyRoutineCreateEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsDailyRoutineListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsDailyRoutineListEventTypeEnum = typeof MeetingsEventsDailyRoutineListEventTypeEnum[keyof typeof MeetingsEventsDailyRoutineListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsInChargeListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsInChargeListEventTypeEnum = typeof MeetingsEventsInChargeListEventTypeEnum[keyof typeof MeetingsEventsInChargeListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsListEventTypeEnum = typeof MeetingsEventsListEventTypeEnum[keyof typeof MeetingsEventsListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsMyDailyEventsListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsMyDailyEventsListEventTypeEnum = typeof MeetingsEventsMyDailyEventsListEventTypeEnum[keyof typeof MeetingsEventsMyDailyEventsListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsOwnPlanningListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsOwnPlanningListEventTypeEnum = typeof MeetingsEventsOwnPlanningListEventTypeEnum[keyof typeof MeetingsEventsOwnPlanningListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsPublicListListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsPublicListListEventTypeEnum = typeof MeetingsEventsPublicListListEventTypeEnum[keyof typeof MeetingsEventsPublicListListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsSupervisorListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsSupervisorListEventTypeEnum = typeof MeetingsEventsSupervisorListEventTypeEnum[keyof typeof MeetingsEventsSupervisorListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityApplyDailyRoutineCreateEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityApplyDailyRoutineCreateEventTypeEnum = typeof MeetingsEventsWithActivityApplyDailyRoutineCreateEventTypeEnum[keyof typeof MeetingsEventsWithActivityApplyDailyRoutineCreateEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityDailyRoutineListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityDailyRoutineListEventTypeEnum = typeof MeetingsEventsWithActivityDailyRoutineListEventTypeEnum[keyof typeof MeetingsEventsWithActivityDailyRoutineListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityInChargeListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityInChargeListEventTypeEnum = typeof MeetingsEventsWithActivityInChargeListEventTypeEnum[keyof typeof MeetingsEventsWithActivityInChargeListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityListEventTypeEnum = typeof MeetingsEventsWithActivityListEventTypeEnum[keyof typeof MeetingsEventsWithActivityListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityMyDailyEventsListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityMyDailyEventsListEventTypeEnum = typeof MeetingsEventsWithActivityMyDailyEventsListEventTypeEnum[keyof typeof MeetingsEventsWithActivityMyDailyEventsListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityOwnPlanningListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityOwnPlanningListEventTypeEnum = typeof MeetingsEventsWithActivityOwnPlanningListEventTypeEnum[keyof typeof MeetingsEventsWithActivityOwnPlanningListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivityPublicListListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivityPublicListListEventTypeEnum = typeof MeetingsEventsWithActivityPublicListListEventTypeEnum[keyof typeof MeetingsEventsWithActivityPublicListListEventTypeEnum];
/**
 * @export
 */
export const MeetingsEventsWithActivitySupervisorListEventTypeEnum = {
    Concret: 'CONCRET',
    JourneeType: 'JOURNEE_TYPE'
} as const;
export type MeetingsEventsWithActivitySupervisorListEventTypeEnum = typeof MeetingsEventsWithActivitySupervisorListEventTypeEnum[keyof typeof MeetingsEventsWithActivitySupervisorListEventTypeEnum];
