/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';
import type { NotNestedDiploma } from './NotNestedDiploma';
import {
    NotNestedDiplomaFromJSON,
    NotNestedDiplomaFromJSONTyped,
    NotNestedDiplomaToJSON,
} from './NotNestedDiploma';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface ReadOnlyNotNestedUser
 */
export interface ReadOnlyNotNestedUser {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly id: string;
    /**
     * Requis. 150 caractères maximum. Uniquement des lettres, nombres et les caractères « @ », « . », « + », « - » et « _ ».
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly username: string;
    /**
     * 
     * @type {GenderEnum}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly gender: GenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly birth_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly usage_first_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly usage_last_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly phone: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly birthdate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly email: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    fonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyNotNestedUser
     */
    fonction_code: string | null;
    /**
     * 
     * @type {Array<NotNestedDiploma>}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly diplomas: Array<NotNestedDiploma>;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyNotNestedUser
     */
    readonly last_sync: Date | null;
}

/**
 * Check if a given object implements the ReadOnlyNotNestedUser interface.
 */
export function instanceOfReadOnlyNotNestedUser(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('username' in value)) return false;
    if (!('gender' in value)) return false;
    if (!('first_name' in value)) return false;
    if (!('last_name' in value)) return false;
    if (!('birth_name' in value)) return false;
    if (!('usage_first_name' in value)) return false;
    if (!('usage_last_name' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('birthdate' in value)) return false;
    if (!('email' in value)) return false;
    if (!('fonction' in value)) return false;
    if (!('fonction_code' in value)) return false;
    if (!('diplomas' in value)) return false;
    if (!('last_sync' in value)) return false;
    return true;
}

export function ReadOnlyNotNestedUserFromJSON(json: any): ReadOnlyNotNestedUser {
    return ReadOnlyNotNestedUserFromJSONTyped(json, false);
}

export function ReadOnlyNotNestedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyNotNestedUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'gender': GenderEnumFromJSON(json['gender']),
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'birth_name': json['birth_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
        'phone': json['phone'],
        'birthdate': (json['birthdate'] == null ? null : new Date(json['birthdate'])),
        'email': json['email'],
        'fonction': json['fonction'],
        'fonction_code': json['fonction_code'],
        'diplomas': ((json['diplomas'] as Array<any>).map(NotNestedDiplomaFromJSON)),
        'last_sync': (json['last_sync'] == null ? null : new Date(json['last_sync'])),
    };
}

export function ReadOnlyNotNestedUserToJSON(value?: Omit<ReadOnlyNotNestedUser, 'id'|'username'|'gender'|'first_name'|'last_name'|'birth_name'|'usage_first_name'|'usage_last_name'|'phone'|'birthdate'|'email'|'diplomas'|'last_sync'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fonction': value['fonction'],
        'fonction_code': value['fonction_code'],
    };
}

