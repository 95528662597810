/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingCommentAuthor } from './MeetingCommentAuthor';
import {
    MeetingCommentAuthorFromJSON,
    MeetingCommentAuthorFromJSONTyped,
    MeetingCommentAuthorToJSON,
} from './MeetingCommentAuthor';
import type { MeetingCommentTag } from './MeetingCommentTag';
import {
    MeetingCommentTagFromJSON,
    MeetingCommentTagFromJSONTyped,
    MeetingCommentTagToJSON,
} from './MeetingCommentTag';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface MeetingComment
 */
export interface MeetingComment {
    /**
     * 
     * @type {string}
     * @memberof MeetingComment
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingComment
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingComment
     */
    readonly participant_id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingComment
     */
    text?: string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof MeetingComment
     */
    type?: TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof MeetingComment
     */
    readonly created_at: Date;
    /**
     * Date must be filled for a comment
     * @type {Date}
     * @memberof MeetingComment
     */
    date: Date;
    /**
     * 
     * @type {MeetingCommentAuthor}
     * @memberof MeetingComment
     */
    author: MeetingCommentAuthor;
    /**
     * 
     * @type {string}
     * @memberof MeetingComment
     */
    readonly author_id: string | null;
    /**
     * List of nested tags associated
     * @type {Array<MeetingCommentTag>}
     * @memberof MeetingComment
     */
    readonly tags: Array<MeetingCommentTag>;
    /**
     * List of ids of the tags associated
     * @type {Array<string>}
     * @memberof MeetingComment
     */
    tags_id?: Array<string>;
    /**
     * Assign the comment as a special comment. allowed_values : (start_interview, middle_interview, end_interview, sgdf_opinion, js_opinion)
     * @type {string}
     * @memberof MeetingComment
     */
    assign_as?: string;
}

/**
 * Check if a given object implements the MeetingComment interface.
 */
export function instanceOfMeetingComment(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('participant_id' in value)) return false;
    if (!('created_at' in value)) return false;
    if (!('date' in value)) return false;
    if (!('author' in value)) return false;
    if (!('author_id' in value)) return false;
    if (!('tags' in value)) return false;
    return true;
}

export function MeetingCommentFromJSON(json: any): MeetingComment {
    return MeetingCommentFromJSONTyped(json, false);
}

export function MeetingCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'] == null ? undefined : json['event'],
        'participant_id': json['participant_id'],
        'text': json['text'] == null ? undefined : json['text'],
        'type': json['type'] == null ? undefined : TypeEnumFromJSON(json['type']),
        'created_at': (new Date(json['created_at'])),
        'date': (new Date(json['date'])),
        'author': MeetingCommentAuthorFromJSON(json['author']),
        'author_id': json['author_id'],
        'tags': ((json['tags'] as Array<any>).map(MeetingCommentTagFromJSON)),
        'tags_id': json['tags_id'] == null ? undefined : json['tags_id'],
        'assign_as': json['assign_as'] == null ? undefined : json['assign_as'],
    };
}

export function MeetingCommentToJSON(value?: Omit<MeetingComment, 'id'|'participant_id'|'created_at'|'author_id'|'tags'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event': value['event'],
        'text': value['text'],
        'type': TypeEnumToJSON(value['type']),
        'date': ((value['date']).toISOString()),
        'author': MeetingCommentAuthorToJSON(value['author']),
        'tags_id': value['tags_id'],
        'assign_as': value['assign_as'],
    };
}

