/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NestedByStructureParticipant } from './NestedByStructureParticipant';
import {
    NestedByStructureParticipantFromJSON,
    NestedByStructureParticipantFromJSONTyped,
    NestedByStructureParticipantToJSON,
} from './NestedByStructureParticipant';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface ParticipantStructure
 */
export interface ParticipantStructure {
    /**
     * 
     * @type {string}
     * @memberof ParticipantStructure
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantStructure
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantStructure
     */
    structure_code: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantStructure
     */
    structure_type: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantStructure
     */
    structure_echelon: string;
    /**
     * 
     * @type {Array<NestedByStructureParticipant>}
     * @memberof ParticipantStructure
     */
    participants: Array<NestedByStructureParticipant>;
}

/**
 * Check if a given object implements the ParticipantStructure interface.
 */
export function instanceOfParticipantStructure(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('label' in value)) return false;
    if (!('structure_code' in value)) return false;
    if (!('structure_type' in value)) return false;
    if (!('structure_echelon' in value)) return false;
    if (!('participants' in value)) return false;
    return true;
}

export function ParticipantStructureFromJSON(json: any): ParticipantStructure {
    return ParticipantStructureFromJSONTyped(json, false);
}

export function ParticipantStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantStructure {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'structure_code': json['structure_code'],
        'structure_type': json['structure_type'],
        'structure_echelon': json['structure_echelon'],
        'participants': ((json['participants'] as Array<any>).map(NestedByStructureParticipantFromJSON)),
    };
}

export function ParticipantStructureToJSON(value?: Omit<ParticipantStructure, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'structure_code': value['structure_code'],
        'structure_type': value['structure_type'],
        'structure_echelon': value['structure_echelon'],
        'participants': ((value['participants'] as Array<any>).map(NestedByStructureParticipantToJSON)),
    };
}

