/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `0` - Non-défini
 * * `270` - Farfadets
 * * `210` - Louveteaux-Jeannettes
 * * `220` - Scouts-Guides
 * * `230` - Pionniers-Caravelles
 * * `240` - Compagnons
 * * `290` - Audace
 * * `180` - Impeesa
 * * `310` - Équipe Groupe
 * * `300` - Responsable Groupe
 * * `600` - Équipe Territorial
 * * `505` - Responsable Territorial
 * * `500` - Délégué Territorial
 * * `900` - Équipe National
 * * `980` - Salarié
 * * `1000` - Autre branche
 * @export
 */
export const BrancheEnum = {
    _0: '0',
    _270: '270',
    _210: '210',
    _220: '220',
    _230: '230',
    _240: '240',
    _290: '290',
    _180: '180',
    _310: '310',
    _300: '300',
    _600: '600',
    _505: '505',
    _500: '500',
    _900: '900',
    _980: '980',
    _1000: '1000'
} as const;
export type BrancheEnum = typeof BrancheEnum[keyof typeof BrancheEnum];


export function instanceOfBrancheEnum(value: any): boolean {
    return Object.values(BrancheEnum).includes(value);
}

export function BrancheEnumFromJSON(json: any): BrancheEnum {
    return BrancheEnumFromJSONTyped(json, false);
}

export function BrancheEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrancheEnum {
    return json as BrancheEnum;
}

export function BrancheEnumToJSON(value?: BrancheEnum | null): any {
    return value as any;
}

