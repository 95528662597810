/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventTypeEnum } from './EventTypeEnum';
import {
    EventTypeEnumFromJSON,
    EventTypeEnumFromJSONTyped,
    EventTypeEnumToJSON,
} from './EventTypeEnum';
import type { NotNestedPublicMeetingParticipant } from './NotNestedPublicMeetingParticipant';
import {
    NotNestedPublicMeetingParticipantFromJSON,
    NotNestedPublicMeetingParticipantFromJSONTyped,
    NotNestedPublicMeetingParticipantToJSON,
} from './NotNestedPublicMeetingParticipant';
import type { NotNestedPublicRoom } from './NotNestedPublicRoom';
import {
    NotNestedPublicRoomFromJSON,
    NotNestedPublicRoomFromJSONTyped,
    NotNestedPublicRoomToJSON,
} from './NotNestedPublicRoom';
import type { NotNestedTeam } from './NotNestedTeam';
import {
    NotNestedTeamFromJSON,
    NotNestedTeamFromJSONTyped,
    NotNestedTeamToJSON,
} from './NotNestedTeam';
import type { PublicActivity } from './PublicActivity';
import {
    PublicActivityFromJSON,
    PublicActivityFromJSONTyped,
    PublicActivityToJSON,
} from './PublicActivity';
import type { VisibilityEnum } from './VisibilityEnum';
import {
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface EventWithActivity
 */
export interface EventWithActivity {
    /**
     * 
     * @type {string}
     * @memberof EventWithActivity
     */
    readonly id: string;
    /**
     * 
     * @type {PublicActivity}
     * @memberof EventWithActivity
     */
    readonly activity: PublicActivity;
    /**
     * 
     * @type {Date}
     * @memberof EventWithActivity
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventWithActivity
     */
    end_date: Date;
    /**
     * Must not be null, a Track must be linked to a meeting
     * @type {string}
     * @memberof EventWithActivity
     */
    readonly meeting_id: string;
    /**
     * 
     * @type {NotNestedPublicRoom}
     * @memberof EventWithActivity
     */
    readonly room: NotNestedPublicRoom;
    /**
     * 
     * @type {NotNestedTeam}
     * @memberof EventWithActivity
     */
    readonly team_in_charge: NotNestedTeam;
    /**
     * 
     * @type {EventTypeEnum}
     * @memberof EventWithActivity
     */
    event_type?: EventTypeEnum;
    /**
     * Commentaire interne en équipe de formation
     * @type {string}
     * @memberof EventWithActivity
     */
    comment?: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipant>}
     * @memberof EventWithActivity
     */
    readonly supervisors: Array<NotNestedPublicMeetingParticipant>;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof EventWithActivity
     */
    visibility?: VisibilityEnum;
}

/**
 * Check if a given object implements the EventWithActivity interface.
 */
export function instanceOfEventWithActivity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('activity' in value)) return false;
    if (!('start_date' in value)) return false;
    if (!('end_date' in value)) return false;
    if (!('meeting_id' in value)) return false;
    if (!('room' in value)) return false;
    if (!('team_in_charge' in value)) return false;
    if (!('supervisors' in value)) return false;
    return true;
}

export function EventWithActivityFromJSON(json: any): EventWithActivity {
    return EventWithActivityFromJSONTyped(json, false);
}

export function EventWithActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventWithActivity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'activity': PublicActivityFromJSON(json['activity']),
        'start_date': (new Date(json['start_date'])),
        'end_date': (new Date(json['end_date'])),
        'meeting_id': json['meeting_id'],
        'room': NotNestedPublicRoomFromJSON(json['room']),
        'team_in_charge': NotNestedTeamFromJSON(json['team_in_charge']),
        'event_type': json['event_type'] == null ? undefined : EventTypeEnumFromJSON(json['event_type']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'supervisors': ((json['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantFromJSON)),
        'visibility': json['visibility'] == null ? undefined : VisibilityEnumFromJSON(json['visibility']),
    };
}

export function EventWithActivityToJSON(value?: Omit<EventWithActivity, 'id'|'activity'|'meeting_id'|'room'|'team_in_charge'|'supervisors'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start_date': ((value['start_date']).toISOString()),
        'end_date': ((value['end_date']).toISOString()),
        'event_type': EventTypeEnumToJSON(value['event_type']),
        'comment': value['comment'],
        'visibility': VisibilityEnumToJSON(value['visibility']),
    };
}

