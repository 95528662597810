/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotNestedResource } from './NotNestedResource';
import {
    NotNestedResourceFromJSON,
    NotNestedResourceFromJSONTyped,
    NotNestedResourceToJSON,
} from './NotNestedResource';
import type { ResourceNode } from './ResourceNode';
import {
    ResourceNodeFromJSON,
    ResourceNodeFromJSONTyped,
    ResourceNodeToJSON,
} from './ResourceNode';

/**
 * 
 * @export
 * @interface ResourceTreeList
 */
export interface ResourceTreeList {
    /**
     * 
     * @type {Array<ResourceNode>}
     * @memberof ResourceTreeList
     */
    readonly tree: Array<ResourceNode>;
    /**
     * 
     * @type {Array<NotNestedResource>}
     * @memberof ResourceTreeList
     */
    readonly list: Array<NotNestedResource>;
}

/**
 * Check if a given object implements the ResourceTreeList interface.
 */
export function instanceOfResourceTreeList(value: object): boolean {
    if (!('tree' in value)) return false;
    if (!('list' in value)) return false;
    return true;
}

export function ResourceTreeListFromJSON(json: any): ResourceTreeList {
    return ResourceTreeListFromJSONTyped(json, false);
}

export function ResourceTreeListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceTreeList {
    if (json == null) {
        return json;
    }
    return {
        
        'tree': ((json['tree'] as Array<any>).map(ResourceNodeFromJSON)),
        'list': ((json['list'] as Array<any>).map(NotNestedResourceFromJSON)),
    };
}

export function ResourceTreeListToJSON(value?: Omit<ResourceTreeList, 'tree'|'list'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

