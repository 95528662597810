/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NestedMeetingComment } from './NestedMeetingComment';
import {
    NestedMeetingCommentFromJSON,
    NestedMeetingCommentFromJSONTyped,
    NestedMeetingCommentToJSON,
} from './NestedMeetingComment';
import type { NotNestedMeetingRole } from './NotNestedMeetingRole';
import {
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleFromJSONTyped,
    NotNestedMeetingRoleToJSON,
} from './NotNestedMeetingRole';
import type { SessionStatusEnum } from './SessionStatusEnum';
import {
    SessionStatusEnumFromJSON,
    SessionStatusEnumFromJSONTyped,
    SessionStatusEnumToJSON,
} from './SessionStatusEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedOpinionParticipant
 */
export interface PatchedOpinionParticipant {
    /**
     * 
     * @type {string}
     * @memberof PatchedOpinionParticipant
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOpinionParticipant
     */
    readonly meeting_id?: string;
    /**
     * 
     * @type {NotNestedMeetingRole}
     * @memberof PatchedOpinionParticipant
     */
    readonly role?: NotNestedMeetingRole;
    /**
     * 
     * @type {string}
     * @memberof PatchedOpinionParticipant
     */
    readonly admin_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOpinionParticipant
     */
    readonly admin_last_name?: string;
    /**
     * 
     * @type {NestedMeetingComment}
     * @memberof PatchedOpinionParticipant
     */
    js_opinion?: NestedMeetingComment;
    /**
     * 
     * @type {NestedMeetingComment}
     * @memberof PatchedOpinionParticipant
     */
    sgdf_opinion?: NestedMeetingComment;
    /**
     * 
     * @type {SessionStatusEnum}
     * @memberof PatchedOpinionParticipant
     */
    session_status?: SessionStatusEnum;
}

/**
 * Check if a given object implements the PatchedOpinionParticipant interface.
 */
export function instanceOfPatchedOpinionParticipant(value: object): boolean {
    return true;
}

export function PatchedOpinionParticipantFromJSON(json: any): PatchedOpinionParticipant {
    return PatchedOpinionParticipantFromJSONTyped(json, false);
}

export function PatchedOpinionParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedOpinionParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'meeting_id': json['meeting_id'] == null ? undefined : json['meeting_id'],
        'role': json['role'] == null ? undefined : NotNestedMeetingRoleFromJSON(json['role']),
        'admin_first_name': json['admin_first_name'] == null ? undefined : json['admin_first_name'],
        'admin_last_name': json['admin_last_name'] == null ? undefined : json['admin_last_name'],
        'js_opinion': json['js_opinion'] == null ? undefined : NestedMeetingCommentFromJSON(json['js_opinion']),
        'sgdf_opinion': json['sgdf_opinion'] == null ? undefined : NestedMeetingCommentFromJSON(json['sgdf_opinion']),
        'session_status': json['session_status'] == null ? undefined : SessionStatusEnumFromJSON(json['session_status']),
    };
}

export function PatchedOpinionParticipantToJSON(value?: Omit<PatchedOpinionParticipant, 'id'|'meeting_id'|'role'|'admin_first_name'|'admin_last_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'js_opinion': NestedMeetingCommentToJSON(value['js_opinion']),
        'sgdf_opinion': NestedMeetingCommentToJSON(value['sgdf_opinion']),
        'session_status': SessionStatusEnumToJSON(value['session_status']),
    };
}

