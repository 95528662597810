/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface ResourceNode
 */
export interface ResourceNode {
    /**
     * 
     * @type {string}
     * @memberof ResourceNode
     */
    id?: string;
    /**
     * 
     * @type {Array<ResourceNode>}
     * @memberof ResourceNode
     */
    children: Array<ResourceNode>;
}

/**
 * Check if a given object implements the ResourceNode interface.
 */
export function instanceOfResourceNode(value: object): boolean {
    if (!('children' in value)) return false;
    return true;
}

export function ResourceNodeFromJSON(json: any): ResourceNode {
    return ResourceNodeFromJSONTyped(json, false);
}

export function ResourceNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceNode {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'children': ((json['children'] as Array<any>).map(ResourceNodeFromJSON)),
    };
}

export function ResourceNodeToJSON(value?: ResourceNode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'children': ((value['children'] as Array<any>).map(ResourceNodeToJSON)),
    };
}

