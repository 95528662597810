/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocTemplate,
  MeetingParticipant,
  PaginatedDocTemplateList,
  PaginatedParticipantStructureList,
  PandasTeamDailyTaskByTask,
  PandasTeamDailyTaskByTeam,
  ParticipantList,
  ParticipantListDirection,
  ParticipantStructure,
  Pv,
} from '../models/index';
import {
    DocTemplateFromJSON,
    DocTemplateToJSON,
    MeetingParticipantFromJSON,
    MeetingParticipantToJSON,
    PaginatedDocTemplateListFromJSON,
    PaginatedDocTemplateListToJSON,
    PaginatedParticipantStructureListFromJSON,
    PaginatedParticipantStructureListToJSON,
    PandasTeamDailyTaskByTaskFromJSON,
    PandasTeamDailyTaskByTaskToJSON,
    PandasTeamDailyTaskByTeamFromJSON,
    PandasTeamDailyTaskByTeamToJSON,
    ParticipantListFromJSON,
    ParticipantListToJSON,
    ParticipantListDirectionFromJSON,
    ParticipantListDirectionToJSON,
    ParticipantStructureFromJSON,
    ParticipantStructureToJSON,
    PvFromJSON,
    PvToJSON,
} from '../models/index';

export interface ExportsParticipantsExportMultipleRetrieveRequest {
    doctemplateUuid: string;
    participantUuid: string;
}

export interface ExportsParticipantsExportRetrieveRequest {
    doctemplateUuid: string;
    participantUuid: string;
}

export interface MeetingsExportsExportRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsExportsListRequest {
    meetingUuid: string;
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
}

export interface MeetingsExportsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsTableExportsParticipantListDirectionRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsTableExportsParticipantListRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsTableExportsProcesVerbalRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsTableExportsTeamsdailytasksbytaskRetrieveRequest {
    meetingUuid: string;
}

export interface MeetingsTableExportsTeamsdailytasksbyteamRetrieveRequest {
    meetingUuid: string;
}

export interface ParticipantsExportsProcesVerbalRetrieveRequest {
    centreRessource?: string;
    endDate?: Date;
    meetingType?: Array<string>;
    startDate?: Date;
}

export interface StructureExportsExportRetrieveRequest {
    structureUuid: string;
    centreRessource?: string;
    docTemplate?: string;
    endDate?: Date;
    meetingType?: Array<string>;
    startDate?: Date;
}

export interface StructureExportsListRequest {
    centreRessource?: string;
    endDate?: Date;
    limit?: number;
    meetingType?: Array<string>;
    offset?: number;
    ordering?: string;
    search?: string;
    startDate?: Date;
}

export interface StructureExportsListByGroupeListRequest {
    centreRessource?: string;
    endDate?: Date;
    limit?: number;
    meetingType?: Array<string>;
    offset?: number;
    ordering?: string;
    search?: string;
    startDate?: Date;
}

export interface StructureExportsListByTerritoireListRequest {
    centreRessource?: string;
    endDate?: Date;
    limit?: number;
    meetingType?: Array<string>;
    offset?: number;
    ordering?: string;
    search?: string;
    startDate?: Date;
}

/**
 * 
 */
export class ExportsApi extends runtime.BaseAPI {

    /**
     * Génère un export compilé pour un gestionnaire
     */
    async exportsParticipantsExportMultipleRetrieveRaw(requestParameters: ExportsParticipantsExportMultipleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['doctemplateUuid'] == null) {
            throw new runtime.RequiredError(
                'doctemplateUuid',
                'Required parameter "doctemplateUuid" was null or undefined when calling exportsParticipantsExportMultipleRetrieve().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling exportsParticipantsExportMultipleRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{doctemplate_uuid}/participants/export-multiple/`.replace(`{${"doctemplate_uuid"}}`, encodeURIComponent(String(requestParameters['doctemplateUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Génère un export compilé pour un gestionnaire
     */
    async exportsParticipantsExportMultipleRetrieve(requestParameters: ExportsParticipantsExportMultipleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.exportsParticipantsExportMultipleRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportsParticipantsExportRetrieveRaw(requestParameters: ExportsParticipantsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['doctemplateUuid'] == null) {
            throw new runtime.RequiredError(
                'doctemplateUuid',
                'Required parameter "doctemplateUuid" was null or undefined when calling exportsParticipantsExportRetrieve().'
            );
        }

        if (requestParameters['participantUuid'] == null) {
            throw new runtime.RequiredError(
                'participantUuid',
                'Required parameter "participantUuid" was null or undefined when calling exportsParticipantsExportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/exports/{doctemplate_uuid}/participants/{participant_uuid}/export/`.replace(`{${"doctemplate_uuid"}}`, encodeURIComponent(String(requestParameters['doctemplateUuid']))).replace(`{${"participant_uuid"}}`, encodeURIComponent(String(requestParameters['participantUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     */
    async exportsParticipantsExportRetrieve(requestParameters: ExportsParticipantsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.exportsParticipantsExportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This viewset provides `list`, `retrieve` for a specified meeting actions.
     */
    async meetingsExportsExportRetrieveRaw(requestParameters: MeetingsExportsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsExportsExportRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsExportsExportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/exports/{uuid}/export/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * This viewset provides `list`, `retrieve` for a specified meeting actions.
     */
    async meetingsExportsExportRetrieve(requestParameters: MeetingsExportsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.meetingsExportsExportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the exports available for a meeting
     */
    async meetingsExportsListRaw(requestParameters: MeetingsExportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDocTemplateList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsExportsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/exports/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDocTemplateListFromJSON(jsonValue));
    }

    /**
     * List the exports available for a meeting
     */
    async meetingsExportsList(requestParameters: MeetingsExportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDocTemplateList> {
        const response = await this.meetingsExportsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve an export for a meeting
     */
    async meetingsExportsRetrieveRaw(requestParameters: MeetingsExportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocTemplate>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsExportsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsExportsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/exports/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocTemplateFromJSON(jsonValue));
    }

    /**
     * Retrieve an export for a meeting
     */
    async meetingsExportsRetrieve(requestParameters: MeetingsExportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocTemplate> {
        const response = await this.meetingsExportsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsTableExportsParticipantListDirectionRetrieveRaw(requestParameters: MeetingsTableExportsParticipantListDirectionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantListDirection>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTableExportsParticipantListDirectionRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/table-exports/participant-list-direction/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantListDirectionFromJSON(jsonValue));
    }

    /**
     */
    async meetingsTableExportsParticipantListDirectionRetrieve(requestParameters: MeetingsTableExportsParticipantListDirectionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantListDirection> {
        const response = await this.meetingsTableExportsParticipantListDirectionRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsTableExportsParticipantListRetrieveRaw(requestParameters: MeetingsTableExportsParticipantListRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTableExportsParticipantListRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/table-exports/participant-list/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantListFromJSON(jsonValue));
    }

    /**
     */
    async meetingsTableExportsParticipantListRetrieve(requestParameters: MeetingsTableExportsParticipantListRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantList> {
        const response = await this.meetingsTableExportsParticipantListRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsTableExportsProcesVerbalRetrieveRaw(requestParameters: MeetingsTableExportsProcesVerbalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pv>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTableExportsProcesVerbalRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/table-exports/proces-verbal/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PvFromJSON(jsonValue));
    }

    /**
     */
    async meetingsTableExportsProcesVerbalRetrieve(requestParameters: MeetingsTableExportsProcesVerbalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pv> {
        const response = await this.meetingsTableExportsProcesVerbalRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsTableExportsTeamsdailytasksbytaskRetrieveRaw(requestParameters: MeetingsTableExportsTeamsdailytasksbytaskRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandasTeamDailyTaskByTask>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTableExportsTeamsdailytasksbytaskRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/table-exports/teamsdailytasksbytask/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandasTeamDailyTaskByTaskFromJSON(jsonValue));
    }

    /**
     */
    async meetingsTableExportsTeamsdailytasksbytaskRetrieve(requestParameters: MeetingsTableExportsTeamsdailytasksbytaskRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandasTeamDailyTaskByTask> {
        const response = await this.meetingsTableExportsTeamsdailytasksbytaskRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async meetingsTableExportsTeamsdailytasksbyteamRetrieveRaw(requestParameters: MeetingsTableExportsTeamsdailytasksbyteamRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandasTeamDailyTaskByTeam>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsTableExportsTeamsdailytasksbyteamRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/table-exports/teamsdailytasksbyteam/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandasTeamDailyTaskByTeamFromJSON(jsonValue));
    }

    /**
     */
    async meetingsTableExportsTeamsdailytasksbyteamRetrieve(requestParameters: MeetingsTableExportsTeamsdailytasksbyteamRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandasTeamDailyTaskByTeam> {
        const response = await this.meetingsTableExportsTeamsdailytasksbyteamRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async participantsExportsProcesVerbalRetrieveRaw(requestParameters: ParticipantsExportsProcesVerbalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pv>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['meetingType'] != null) {
            queryParameters['meeting_type'] = requestParameters['meetingType'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/participants-exports/proces-verbal/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PvFromJSON(jsonValue));
    }

    /**
     */
    async participantsExportsProcesVerbalRetrieve(requestParameters: ParticipantsExportsProcesVerbalRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pv> {
        const response = await this.participantsExportsProcesVerbalRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports le document liés à des participants d\'une structure
     */
    async structureExportsExportRetrieveRaw(requestParameters: StructureExportsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantStructure>> {
        if (requestParameters['structureUuid'] == null) {
            throw new runtime.RequiredError(
                'structureUuid',
                'Required parameter "structureUuid" was null or undefined when calling structureExportsExportRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['docTemplate'] != null) {
            queryParameters['doc_template'] = requestParameters['docTemplate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['meetingType'] != null) {
            queryParameters['meeting_type'] = requestParameters['meetingType'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structure-exports/{structure_uuid}/export/`.replace(`{${"structure_uuid"}}`, encodeURIComponent(String(requestParameters['structureUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantStructureFromJSON(jsonValue));
    }

    /**
     * Exports le document liés à des participants d\'une structure
     */
    async structureExportsExportRetrieve(requestParameters: StructureExportsExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantStructure> {
        const response = await this.structureExportsExportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List of structure with participants
     */
    async structureExportsListRaw(requestParameters: StructureExportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedParticipantStructureList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingType'] != null) {
            queryParameters['meeting_type'] = requestParameters['meetingType'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structure-exports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedParticipantStructureListFromJSON(jsonValue));
    }

    /**
     * List of structure with participants
     */
    async structureExportsList(requestParameters: StructureExportsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedParticipantStructureList> {
        const response = await this.structureExportsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste des groupes avec participants associés
     */
    async structureExportsListByGroupeListRaw(requestParameters: StructureExportsListByGroupeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedParticipantStructureList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingType'] != null) {
            queryParameters['meeting_type'] = requestParameters['meetingType'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structure-exports/list-by-groupe/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedParticipantStructureListFromJSON(jsonValue));
    }

    /**
     * Liste des groupes avec participants associés
     */
    async structureExportsListByGroupeList(requestParameters: StructureExportsListByGroupeListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedParticipantStructureList> {
        const response = await this.structureExportsListByGroupeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste des territoire avec participants associés
     */
    async structureExportsListByTerritoireListRaw(requestParameters: StructureExportsListByTerritoireListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedParticipantStructureList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingType'] != null) {
            queryParameters['meeting_type'] = requestParameters['meetingType'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/structure-exports/list-by-territoire/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedParticipantStructureListFromJSON(jsonValue));
    }

    /**
     * Liste des territoire avec participants associés
     */
    async structureExportsListByTerritoireList(requestParameters: StructureExportsListByTerritoireListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedParticipantStructureList> {
        const response = await this.structureExportsListByTerritoireListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
