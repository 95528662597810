/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_sync` - Non synchronisé
 * * `error_unknown` - Erreur : Raison inconnue
 * * `nbafa_not_found` - Erreur : Numéro de candidat BAFA/BAFD non trouvé
 * * `names_not_found` - Erreur : Dossier non trouvé par Prénom + Nom + Date de naissance
 * * `found_not_added` - Candidat trouvé - Non synchronisé
 * * `incoherent_names` - Attention : des données du dossier (Nom, Prénom, ou date denaissance) sont incohérente entre elles
 * * `synchronized` - Candidat synchronisé
 * * `synchronized_verified` - Candidat synchronisé et informations vérifiées
 * @export
 */
export const TagadaCandidateStatusEnum = {
    NotSync: 'not_sync',
    ErrorUnknown: 'error_unknown',
    NbafaNotFound: 'nbafa_not_found',
    NamesNotFound: 'names_not_found',
    FoundNotAdded: 'found_not_added',
    IncoherentNames: 'incoherent_names',
    Synchronized: 'synchronized',
    SynchronizedVerified: 'synchronized_verified'
} as const;
export type TagadaCandidateStatusEnum = typeof TagadaCandidateStatusEnum[keyof typeof TagadaCandidateStatusEnum];


export function instanceOfTagadaCandidateStatusEnum(value: any): boolean {
    return Object.values(TagadaCandidateStatusEnum).includes(value);
}

export function TagadaCandidateStatusEnumFromJSON(json: any): TagadaCandidateStatusEnum {
    return TagadaCandidateStatusEnumFromJSONTyped(json, false);
}

export function TagadaCandidateStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagadaCandidateStatusEnum {
    return json as TagadaCandidateStatusEnum;
}

export function TagadaCandidateStatusEnumToJSON(value?: TagadaCandidateStatusEnum | null): any {
    return value as any;
}

