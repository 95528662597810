/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedOwnMeetingUpdateParticipant
 */
export interface PatchedOwnMeetingUpdateParticipant {
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof PatchedOwnMeetingUpdateParticipant
     */
    food_regime?: string;
    /**
     * Is imported from intranet the 1st time but not synchronized after
     * @type {string}
     * @memberof PatchedOwnMeetingUpdateParticipant
     */
    num_bafa_bafd?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOwnMeetingUpdateParticipant
     */
    hebergement?: string;
}

/**
 * Check if a given object implements the PatchedOwnMeetingUpdateParticipant interface.
 */
export function instanceOfPatchedOwnMeetingUpdateParticipant(value: object): boolean {
    return true;
}

export function PatchedOwnMeetingUpdateParticipantFromJSON(json: any): PatchedOwnMeetingUpdateParticipant {
    return PatchedOwnMeetingUpdateParticipantFromJSONTyped(json, false);
}

export function PatchedOwnMeetingUpdateParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedOwnMeetingUpdateParticipant {
    if (json == null) {
        return json;
    }
    return {
        
        'food_regime': json['food_regime'] == null ? undefined : json['food_regime'],
        'num_bafa_bafd': json['num_bafa_bafd'] == null ? undefined : json['num_bafa_bafd'],
        'hebergement': json['hebergement'] == null ? undefined : json['hebergement'],
    };
}

export function PatchedOwnMeetingUpdateParticipantToJSON(value?: PatchedOwnMeetingUpdateParticipant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'food_regime': value['food_regime'],
        'num_bafa_bafd': value['num_bafa_bafd'],
        'hebergement': value['hebergement'],
    };
}

