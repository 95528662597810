import { Configuration } from "@sgdf/client";
import { apiUrl } from "~~/plugins/api.service";
import {
  authenticationService,
  tokenManager,
} from "./services/authentication.service";

export const getConfiguration = (accessToken?: string) => {
  const notifyStore = useNotifyStore();
  const notifyError = notifyStore.notifyError.bind(notifyStore);
  const notifyDetailed = notifyStore.notifyDetailed.bind(notifyStore);
  return new Configuration({
    accessToken,
    basePath: apiUrl ?? undefined,
    fetchApi: ($fetch as any).native,
    middleware: [
      {
        pre: async (context) => {
          if (context.url.endsWith("/api/auth/jwt/refresh/")) {
            return Promise.resolve(context);
          }

          const access_token = localStorage.getItem("access_token");
          const refresh_token = localStorage.getItem("refresh_token");

          context.init.headers = context.init.headers || {};

          const delay_seconds = 10;

          //Here we check if true, current token has infinite time
          if (
            access_token &&
            !tokenManager.isTokenExpired(access_token, delay_seconds)
          ) {
            //Only add Bearer if access_token exists and not expired
            context.init.headers = {
              ...context.init.headers,
              ["Authorization"]: `JWT ${access_token}`,
            };
            return Promise.resolve(context);
          }

          if (
            refresh_token &&
            !tokenManager.isTokenExpired(refresh_token, delay_seconds)
          ) {
            //Here, we have an expired access_token but a valid refresh_token
            //We try to refresh the access_token
            const resp = await authenticationService.refreshToken();

            if (resp) {
              context.init.headers = {
                ...context.init.headers,
                ["Authorization"]: `JWT ${resp.access}`,
              };

              return Promise.resolve(context);
            }
          }

          const currentMeetingId = localStorage.getItem("current_meeting");

          if (currentMeetingId) {
            const qrCode = localStorage.getItem(
              `qrcode_token:${currentMeetingId}`
            );

            if (qrCode) {
              context.init.headers = {
                ...context.init.headers,
                ["Authorization"]: `QRCode ${qrCode}`,
              };
              return Promise.resolve(context);
            }
          }

          //Here, we had an issue : access_token and refresh_token are expired or not resfreshed
          authenticationService.logout();

          return Promise.resolve(context);
        },
        onError: async (context) => {
          if (context.response && context.response.status >= 400) {
            const contentType = context.response.headers.get("content-type");
            const resp = await (contentType === "application/json"
              ? context.response.json()
              : context.response.text());

            notifyDetailed(
              resp.code ?? "Error",
              resp.detail ?? context.response.status === 500
                ? JSON.stringify(context.error)
                : JSON.stringify(resp)
            );
          } else {
            notifyError(context.error);
          }
        },
        post: async (context) => {
          if (context.response?.status >= 400) {
            const contentType = context.response.headers.get("content-type");
            const resp = await (contentType === "application/json"
              ? context.response.json()
              : context.response.text());
            notifyDetailed(
              resp.code ?? "Error",
              resp.detail ?? context.response.status === 500
                ? "Internal server error"
                : JSON.stringify(resp)
            );
          }
          if (context.response?.status === 401) {
            // In this situation, access is not given to the page
            // Even after a token refresh, we redirect to login page
            authenticationService.logout();
            if (!window.location.href.endsWith(RouteUtils.login)) {
              await navigateTo(RouteUtils.login);
            }
          }
        },
      },
    ],
  });
};
